import React from 'react'
import { twMerge } from 'tailwind-merge'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from './form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from './form/field-label'

export type RadioGroupProps<T extends string> = FieldErrorAndDescriptionProps &
  FieldLabelProps & {
    options: RadioGroupDataInterface<T>[] | undefined
    selected: T | undefined
    className?: string
    disabled?: boolean
    onChange: (value: T) => void
    optionsWrapperClassName?: string
    optionWrapperClassName?: string
    selectedOptionWrapperClassName?: string
    selectedOptionLabelClassName?: string
    optionClassName?: string
  }

export interface RadioGroupDataInterface<T> {
  id: T
  caption: string
  additionalInfo?: JSX.Element
}

const RadioGroup = <T extends string>({
  options,
  selected,
  onChange,
  disabled,
  error,
  label,
  required,
  className,
  labelClassName,
  optionsWrapperClassName,
  optionWrapperClassName,
  selectedOptionWrapperClassName,
  optionClassName,
}: RadioGroupProps<T>) => {
  return (
    <div className={twMerge('flex flex-col gap-1', className)}>
      <FieldLabel label={label} labelClassName={labelClassName} required={required} />
      <ul className={twMerge('flex gap-5 items-center', optionsWrapperClassName)}>
        {options ? (
          options.map(option => (
            <li
              key={option.id}
              className={twMerge(
                optionWrapperClassName,
                option.id === selected && selectedOptionWrapperClassName,
              )}
            >
              <label
                className={twMerge(
                  'relative r flex gap-2 items-center w-fit',
                  !disabled && 'cursor-pointer',
                )}
              >
                <input
                  className={twMerge(
                    'outline-none border-2',
                    disabled ? 'border-gray-100/70' : 'border-gray-100',
                    'w-[20px] h-[20px] rounded-full',
                    option.id === selected ? 'bg-white' : 'bg-transparent',
                    'appearance-none main-transition-colors focus-visible:ring-2 ring-blue ring-offset-1 flex-shrink-0',
                  )}
                  disabled={disabled}
                  checked={option.id === selected}
                  value={option.caption}
                  onChange={() => onChange(option.id)}
                  type="radio"
                />
                <svg
                  width={20}
                  height={20}
                  className={twMerge(
                    'absolute main-transition-colors',
                    option.id === selected ? 'fill-green' : 'fill-transparent',
                    disabled && 'opacity-70',
                  )}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.084 10.042C20.084 15.588 15.588 20.084 10.042 20.084C4.49594 20.084 0 15.588 0 10.042C0 4.49594 4.49594 0 10.042 0C15.588 0 20.084 4.49594 20.084 10.042ZM8.88044 15.3591L16.3309 7.90864C16.5839 7.65565 16.5839 7.24542 16.3309 6.99243L15.4147 6.07622C15.1618 5.82319 14.7515 5.82319 14.4985 6.07622L8.42231 12.1523L5.58549 9.31553C5.3325 9.06254 4.92227 9.06254 4.66924 9.31553L3.75303 10.2317C3.50004 10.4847 3.50004 10.895 3.75303 11.1479L7.96419 15.3591C8.21722 15.6121 8.62741 15.6121 8.88044 15.3591Z"
                  />
                </svg>
                <div className={'flex items-center gap-3'}>
                  <span
                    className={twMerge(
                      'main-transition-colors',
                      option.id === selected ? 'text-darkblue' : 'text-darkblue/50',
                      disabled && 'opacity-70',
                      optionClassName,
                      'text-left',
                    )}
                  >
                    {option.caption}
                  </span>
                  {option.additionalInfo}
                </div>
              </label>
            </li>
          ))
        ) : (
          <>
            <li
              key={'first-skeleton'}
              className={
                'text-transparent bg-gray-600 animate-pulse rounded-full h-[20px] w-[20px]'
              }
            >
              .
            </li>
            <li
              key={'second-skeleton'}
              className={
                'text-transparent bg-gray-600 animate-pulse rounded-full h-[20px] w-[20px]'
              }
            >
              .
            </li>
          </>
        )}
      </ul>
      <FieldErrorAndDescription error={error} />
    </div>
  )
}

export default RadioGroup
