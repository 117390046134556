import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import CourseSelect from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-course-item/components/course-select'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { TriggerCourseEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import React from 'react'

export interface TriggerCourseItemProps
  extends EditTriggerItemInterface<TriggerCourseEditInterface> {}

const TriggerCourseItem = ({
  trigger,
  triggers,
  idx,
  errors,
  setRules,
  onDelete,
  isStatic,
}: TriggerCourseItemProps) => (
  <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
    {({ data, renderTriggerLabel, onOpen }) => (
      <>
        <CourseSelect
          isStatic={isStatic}
          courseId={trigger.course.id}
          triggerId={trigger.id}
          courseData={data.courseData}
          course={trigger.course}
          errors={errors}
          renderTriggerLabel={renderTriggerLabel}
          onOpen={onOpen}
          onChange={data =>
            setRules(prev => {
              return {
                ...prev,
                triggers: triggers.map((el, i) => {
                  if (idx === i) {
                    return {
                      ...el,
                      course: { id: data },
                    }
                  } else {
                    return el
                  }
                }),
              }
            })
          }
        />
      </>
    )}
  </EditTriggerWrapper>
)

export default TriggerCourseItem
