import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionCreateUserEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { getLocaleSelectOptions } from 'shared/utils/locale/get-locale-select-options'

interface ActionCreateUserItemProps
  extends EditActionItemInterface<ActionCreateUserEditInterface> {}

const ActionCreateUserItem = ({
  actions,
  action,
  errors,
  setRules,
  idx,
  onDelete,
}: ActionCreateUserItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ renderActionLabel }) => (
        <>
          <FormSelect
            htmlFor={'none'}
            labelClassName={'w-full'}
            label={renderActionLabel({
              label: t('dashboard.automation_rule.action.new_user_language'),
            })}
            placeholder={t('dashboard.automation_rule.choose_language_of_user')}
            value={action.locale}
            data={getLocaleSelectOptions(t)}
            onChange={data =>
              setRules(prev => {
                return {
                  ...prev,
                  actions: actions.map((el, i) => {
                    if (idx === i) {
                      return { ...el, locale: data }
                    } else {
                      return el
                    }
                  }),
                }
              })
            }
            error={errors.automationActions[action.id]?.locale?.join('')}
          />
        </>
      )}
    </EditActionWrapper>
  )
}

export default ActionCreateUserItem
