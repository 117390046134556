import React from 'react'

function CampaignCompleteIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="39"
      height="41"
      viewBox="0 0 39 41"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={'none'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75104 30.9259C3.49183 32.3519 4.57216 33.6915 6.04127 33.7345L7.29422 33.7776C8.3314 33.8208 9.2387 34.5122 9.49796 35.506L9.84369 36.7159C10.2325 38.0988 11.7881 38.8333 13.1277 38.2715L14.2945 37.7962C15.2451 37.4073 16.3685 37.6234 17.06 38.4011L17.8811 39.3518C18.8318 40.432 20.5602 40.432 21.5108 39.3087L22.3319 38.358C23.0234 37.5804 24.1037 37.3208 25.0543 37.71L26.2211 38.1853C27.5605 38.7471 29.0731 38.0126 29.4619 36.5866L29.8077 35.3766C30.0669 34.3829 30.9745 33.6483 32.0114 33.6049L33.2644 33.5184C34.6904 33.4319 35.7706 32.0924 35.5115 30.6663L35.2954 29.4564C35.1227 28.4192 35.598 27.4255 36.5053 26.907L37.5856 26.302C38.8385 25.6106 39.2277 23.9253 38.3632 22.7585L37.6286 21.7647C37.0237 20.9437 37.0237 19.7769 37.6286 18.9561L38.3632 17.919C39.1843 16.7522 38.7954 15.0669 37.5421 14.4189L36.4618 13.814C35.5542 13.3386 35.0358 12.3014 35.2088 11.3077L35.425 10.0978C35.6842 8.67181 34.6038 7.33225 33.1348 7.28918L31.8818 7.24609C30.8446 7.20298 29.9373 6.51153 29.6781 5.51779L29.3324 4.30787C28.9435 2.92494 27.3879 2.19043 26.0483 2.75225L24.7089 3.22728C23.7582 3.61611 22.6348 3.39999 21.9433 2.62233L21.1222 1.67168C20.1716 0.634518 18.4863 0.634518 17.5357 1.75819L16.7146 2.70885C16.0231 3.4865 14.9428 3.74601 13.9922 3.3569L12.8254 2.88156C11.4859 2.31971 9.97333 3.05427 9.5845 4.48031L9.23878 5.69024C8.97954 6.68402 8.07199 7.41855 7.03505 7.46199L5.78207 7.5485C4.35608 7.635 3.27581 8.97448 3.53499 10.4005L3.75111 11.6105C3.92384 12.6476 3.44847 13.6414 2.54119 14.1599L1.46091 14.7648C0.207928 15.4563 -0.181202 17.1415 0.683258 18.3083L1.4178 19.3021C2.02277 20.1232 2.02277 21.29 1.4178 22.1107L0.683258 23.1478C-0.137842 24.3146 0.250988 25.9999 1.50435 26.6479L2.58465 27.2529C3.49224 27.7282 4.01062 28.7654 3.83761 29.7591L3.75104 30.9259ZM13.6897 17.5736L17.1033 20.9871L25.2702 12.8634L28.8138 16.4069L17.1034 28.1172L10.1464 21.1601L13.6897 17.5736Z"
      />
    </svg>
  )
}

export default CampaignCompleteIcon
