import { TagCreateInterface, TagInterface } from 'modules/tags/types/tag-interface'
import httpClient from 'shared/utils/http-client'

export const TAGS_API = '/api/dashboard/customer/contact/tags'

export const removeTag = (id: number) => httpClient.delete(`${TAGS_API}/${id}`)

export const updateTag = (id: number, data: Pick<TagInterface, 'name'>) =>
  httpClient.put(`${TAGS_API}/${id}`, data)

export const createTag = (data: TagCreateInterface) => httpClient.post<TagInterface>(TAGS_API, data)
