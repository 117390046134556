import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionPipelineStageEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface ActionAssignPipelineStageItemProps
  extends EditActionItemInterface<ActionPipelineStageEditInterface> {}

const ActionAssignPipelineStageItem = ({
  actions,
  action,
  onDelete,
  setRules,
  errors,
  idx,
}: ActionAssignPipelineStageItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <FormSelect
          onOpen={onOpen}
          htmlFor={'none'}
          labelClassName={'w-full'}
          label={renderActionLabel({
            label: t('dashboard.automation_rule.crm.pipeline_stage'),
            additionalLabel: action.pipelineStage.id ? (
              <LinkWithoutPrefetch
                rel="noreferrer"
                target={'_blank'}
                className={`outline-none group self-center`}
                href={'/crm-pipeline'}
              >
                <OpenInNewIcon
                  className={'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'}
                />
              </LinkWithoutPrefetch>
            ) : undefined,
          })}
          placeholder={t('dashboard.automation_rule.crm.select_pipeline_stage')}
          value={action.pipelineStage.id}
          data={data.pipelineStageData}
          staticData={getDataFromObj(action.pipelineStage)}
          onChange={data =>
            setRules(prev => {
              return {
                ...prev,
                actions: actions.map((el, i) => {
                  if (idx === i) {
                    return {
                      ...el,
                      pipelineStage: { id: data },
                    }
                  } else {
                    return el
                  }
                }),
              }
            })
          }
          error={errors.automationActions[action.id]?.pipelineStage?.join('')}
        />
      )}
    </EditActionWrapper>
  )
}
export default ActionAssignPipelineStageItem
