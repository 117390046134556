import React from 'react'

function AutomationRuleActionIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      {...props}
      width="63"
      height="60"
      viewBox="0 0 63 60"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7732 27.5558C42.1021 27.0626 41.6089 26.5694 41.1157 26.5694H32.4019V12.9236C32.4019 12.2661 31.4154 11.9372 31.0865 12.5947L21.5509 31.6662C21.222 31.9952 21.5509 32.6527 22.2084 32.6527H30.9222V46.134C30.9222 46.9562 31.9087 47.1205 32.2376 46.463L41.7732 27.5558Z"
        fill="#64748B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.6977 24.3826H60.6974L57.3533 24.3823C54.9442 12.1595 44.0877 3.28823 31.5387 3.28823C19.0504 3.28823 8.26021 12.1596 5.88206 24.3823H2.53906C3.12658 20.9711 4.30145 17.7216 6.0308 14.7238C7.72275 11.7909 9.89129 9.18962 12.4762 6.99228C15.0791 4.77958 18.0153 3.05289 21.2029 1.86015C24.5017 0.62585 27.9792 0 31.5387 0C35.099 0 38.5835 0.62585 41.8954 1.86015C45.0974 3.0535 48.0497 4.78019 50.6704 6.99227C53.2754 9.19117 55.4619 11.7924 57.1692 14.7238C58.9165 17.724 60.1037 20.9736 60.6976 24.3823L60.6977 24.3826Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.95353 31.5234L0.00080061 26.1681H7.90625L3.95353 31.5234Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.55229 35.9572H2.55259L5.89669 35.9575C8.30578 48.1804 19.1623 57.0516 31.7112 57.0516C44.1996 57.0516 54.9898 48.1803 57.3679 35.9575H60.7109C60.1234 39.3688 58.9485 42.6182 57.2192 45.616C55.5272 48.549 53.3587 51.1502 50.7738 53.3476C48.1708 55.5603 45.2347 57.287 42.047 58.4797C38.7482 59.714 35.2708 60.3398 31.7112 60.3398C28.1509 60.3398 24.6664 59.714 21.3546 58.4797C18.1526 57.2864 15.2003 55.5597 12.5796 53.3476C9.97456 51.1487 7.78802 48.5474 6.08075 45.616C4.33342 42.6159 3.14629 39.3663 2.55235 35.9575L2.55229 35.9572Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.2965 28.8164L63.2492 34.1717H55.3438L59.2965 28.8164Z"
      />
    </svg>
  )
}

export default AutomationRuleActionIcon
