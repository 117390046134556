import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionCampaignEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import { getCampaignStepsListNextjsUrl } from 'modules/campaigns/campaign-steps/utils/get-campaign-steps-list-nextjs-url'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface ActionCampaignItemProps extends EditActionItemInterface<ActionCampaignEditInterface> {}

const ActionCampaignItem = ({
  actions,
  action,
  errors,
  setRules,
  onDelete,
  idx,
}: ActionCampaignItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <>
          <FormSelect
            onOpen={onOpen}
            htmlFor={'none'}
            labelClassName={'w-full'}
            label={renderActionLabel({
              label: t('dashboard.campaign.title'),
              additionalLabel: action.campaign.id ? (
                <LinkWithoutPrefetch
                  rel="noreferrer"
                  target={'_blank'}
                  className={`outline-none group self-center`}
                  href={getCampaignStepsListNextjsUrl(action.campaign.id)}
                >
                  <OpenInNewIcon
                    className={
                      'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'
                    }
                  />
                </LinkWithoutPrefetch>
              ) : undefined,
            })}
            placeholder={t('dashboard.automation_rule.choose_campaign')}
            value={action.campaign.id}
            data={data.campaignData}
            staticData={getDataFromObj(action.campaign)}
            onChange={data =>
              setRules(prev => {
                return {
                  ...prev,
                  actions: actions.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        campaign: { id: data },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
            }
            error={errors.automationActions[action.id]?.campaign?.join('')}
          />
        </>
      )}
    </EditActionWrapper>
  )
}

export default ActionCampaignItem
