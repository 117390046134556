import React from 'react'

function FormSubscribedIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="39"
      height="37"
      viewBox="0 0 39 37"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={'none'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4811 0.50007H2.11624C0.938396 0.50007 0.00390625 1.43427 0.00390625 2.6124V10.899C0.00390625 12.0768 0.978866 13.0113 2.11624 13.0113H14.5055V11.4271C14.5055 8.90872 16.5365 6.8777 19.0549 6.8777C20.2736 6.8777 21.4109 7.36506 22.2637 8.21817C23.1169 9.07134 23.6042 10.2086 23.6042 11.427V13.0112H36.4403C37.6181 13.0112 38.5526 12.0363 38.5526 10.8989V2.61233C38.5931 1.47496 37.6589 0.5 36.4808 0.5L36.4811 0.50007Z"
      />
      <path
        stroke={'none'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0351 20.078C30.2633 20.078 29.5728 20.4843 29.2073 21.0935V21.053C29.2073 19.8752 28.2323 18.9001 27.0544 18.9001C26.2826 18.9001 25.5921 19.3064 25.2266 19.9156V19.8751C25.2266 18.6972 24.2516 17.7222 23.0737 17.7222C22.3018 17.7222 21.6114 18.1286 21.2458 18.7377L21.2456 11.4261C21.2456 10.8574 21.0019 10.2887 20.5956 9.8824C20.1892 9.47609 19.6614 9.23242 19.0519 9.23242C17.8741 9.23242 16.899 10.2074 16.899 11.3853V25.5212C16.899 25.643 16.8177 25.7649 16.6959 25.8057C16.574 25.8462 16.4522 25.8462 16.3709 25.7244L14.1775 23.531C13.4465 22.8 12.2683 22.6778 11.4155 23.2873C10.8873 23.6123 10.5623 24.181 10.4813 24.7902C10.4 25.3994 10.5626 26.0089 10.9686 26.4963L16.4525 33.1175C17.9962 34.9861 20.2709 36.0421 22.7079 36.0421H26.12C28.0291 36.0421 29.8165 35.3111 31.157 33.9705C32.4974 32.6301 33.2285 30.8427 33.2285 28.9335V22.1905C33.188 21.0531 32.213 20.0784 31.0352 20.0784L31.0351 20.078Z"
      />
    </svg>
  )
}

export default FormSubscribedIcon
