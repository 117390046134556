import { RuleSpecificEmailApiInterface } from 'modules/automation-rules/email/types/rule-email-interface'
import 'modules/profile/mailing-settings/types/mailing-settings-double-optin-interface'
import {
  EmailBaseInterface,
  EmailId,
  EmailRecipientEditInterface,
  MailingApiInterface,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import httpClient from 'shared/utils/http-client'

const AUTOMATION_RULES_EMAIL_API = '/api/dashboard/customer/automation/email'
const AUTOMATION_RULES_EMAIL_WITH_RECIPIENT_API =
  '/api/dashboard/customer/automation/email-with-recipient'

export const createRuleEmail = (data: EmailBaseInterface) =>
  httpClient.post<MailingApiInterface>(AUTOMATION_RULES_EMAIL_API, data)

export const createRuleSpecificEmail = (data: EmailRecipientEditInterface) =>
  httpClient.post<RuleSpecificEmailApiInterface>(AUTOMATION_RULES_EMAIL_WITH_RECIPIENT_API, data)

export const updateRuleEmail = (emailId: EmailId, data: EmailBaseInterface) =>
  httpClient.put<MailingApiInterface>(`${AUTOMATION_RULES_EMAIL_API}/${emailId}`, data)

export const updateRuleSpecificEmail = (emailId: EmailId, data: EmailRecipientEditInterface) =>
  httpClient.put<RuleSpecificEmailApiInterface>(
    `${AUTOMATION_RULES_EMAIL_WITH_RECIPIENT_API}/${emailId}`,
    data,
  )
