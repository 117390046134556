import { MAILING_API } from 'shared/components/dashboard-mail-editor/api/email-api'
import {
  EmailId,
  MailingApiInterface,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useEmail(emailId: EmailId, shouldFetch = true) {
  const { user } = useUser()

  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, MailingApiInterface>(
    { method: RequestMethodsEnum.get },
  )
  return useSWR(() => {
    if (user && shouldFetch) {
      return `${MAILING_API}/${emailId}`
    }
  }, fetcher)
}
