import {
  AutomationRuleErrorInterface,
  AutomationRuleViewInterface,
  RuleId,
} from 'modules/automation-rules/types/automation-rule-interface'
import { getRuleData } from 'modules/automation-rules/utils/get-rule-data'
import { isRuleSaveDisabled } from 'modules/automation-rules/utils/is-rule-save-disabled'
import React from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import SaveIcon from 'shared/icons/save-icon'
import { useCreateRule } from '../hooks/use-create-rule'
import { useSaveRule } from '../hooks/use-save-rule'

interface RoleSaveModalProps {
  ruleId?: RuleId
  rule: AutomationRuleViewInterface
  afterSave: (rule: AutomationRuleViewInterface) => Promise<void>
  isFetching: boolean
  setIsFetching: (value: boolean) => void
  setErrors: React.Dispatch<React.SetStateAction<AutomationRuleErrorInterface>>
}

const RuleSave = ({
  ruleId,
  rule,
  afterSave,
  setErrors,
  isFetching,
  setIsFetching,
}: RoleSaveModalProps) => {
  const { t } = useLocoTranslation()
  const { fetcher: saveRule } = useSaveRule()
  const { fetcher: createRule } = useCreateRule({ showFieldsErrorToast: true })

  const onSaveRule = async () => {
    try {
      setIsFetching(true)
      setErrors({ automationTriggers: {}, automationActions: {} })
      const ruleData = getRuleData(rule)
      if (ruleId) {
        const responseData = await saveRule(ruleId, ruleData)
        await afterSave(responseData)
        toast.success(t('dashboard.automation_rule.edit.update_success'))
      } else {
        const responseData = await createRule(ruleData)
        await afterSave(responseData)
        toast.success(t('dashboard.automation_rule.edit.create_success'))
      }
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors.fields) {
          const errors = e.errors.fields as unknown as AutomationRuleErrorInterface
          setErrors(prev => ({ ...prev, ...errors }))
        }
      }
    } finally {
      setIsFetching(false)
    }
  }
  return (
    <PrimaryButton isFetching={isFetching} onClick={onSaveRule} disabled={isRuleSaveDisabled(rule)}>
      <SaveIcon />
      <span className={'hidden lg:flex'}>{t('dashboard.automation_rule.edit.save')}</span>
    </PrimaryButton>
  )
}

export default RuleSave
