import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import EmailSelect from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-email-item/components/email-select'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerEmailLinkEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface TriggerEmailLinkItemProps
  extends EditTriggerItemInterface<TriggerEmailLinkEditInterface> {}

const TriggerEmailLinkItem = ({
  trigger,
  triggers,
  idx,
  errors,
  setRules,
  onDelete,
  isStatic,
}: TriggerEmailLinkItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, onClear }) => (
        <>
          <EmailSelect
            isStatic={isStatic}
            emailData={data.emailData}
            onChange={data => {
              setRules(prev => {
                return {
                  ...prev,
                  triggers: triggers.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        mailing: {
                          id: data,
                        },
                        link: {
                          id: undefined,
                        },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
              onClear(AutomationRuleTriggersDataEnum.email_link)
            }}
            errors={errors}
            triggerId={trigger.id}
            mailingId={trigger.mailing.id}
            onOpen={() => onOpen(AutomationRuleTriggersDataEnum.email)}
            subject={trigger.mailing.currentEmail?.subject}
          />

          {trigger.mailing?.id && (
            <FormSelect
              disabled={isStatic}
              onOpen={() => onOpen(AutomationRuleTriggersDataEnum.email_link)}
              label={t('dashboard.automation_rule.email_link')}
              placeholder={t('dashboard.automation_rule.choose_email_link')}
              value={trigger.link?.id}
              staticData={
                trigger.link &&
                getDataFromObj({ id: trigger.link.id, name: trigger.link.originalUrl })
              }
              data={data.emailLinksData}
              onChange={data =>
                setRules(prev => {
                  return {
                    ...prev,
                    triggers: triggers.map((el, i) => {
                      if (idx === i) {
                        return {
                          ...el,
                          link: {
                            id: data,
                          },
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
              error={errors.automationTriggers[trigger.id]?.link?.join('')}
            />
          )}
        </>
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerEmailLinkItem
