import { defaultActionValues } from 'modules/automation-rules/actions/constants/default-action-values'
import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'

export const getDefaultActionValue = (
  slug: AutomationRuleActionsEnum,
  maxActionId: React.MutableRefObject<number>,
) => {
  const defaultAction = { ...defaultActionValues[slug] }
  defaultAction.id = ++maxActionId.current
  return defaultAction
}
