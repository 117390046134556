export enum AutomationRuleNamesEnum {
  blog = 'blog',
  blog_entity = 'blog_entity',
  page = 'page',
  tag = 'tag',
  funnel = 'funnel',
  funnelStep = 'funnelStep',
  mailing = 'mailing',
  link = 'link',
  course = 'course',
  courseModules = 'courseModules',
  module = 'module',
  lecture = 'lecture',
  courseBundle = 'courseBundle',
  campaign = 'campaign',
  webinar = 'webinar',
  systemeEmail = 'systemeEmail',
  courseAccessType = 'courseAccessType',
  enrollmentAccessType = 'enrollmentAccessType',
  webhookUrl = 'webhookUrl',
  locale = 'locale',
  emailMessageWithRecipient = 'emailMessageWithRecipient',
  community = 'community',
  pipelineStage = 'pipelineStage',
}
