import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import EmailSelect from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-email-item/components/email-select'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerEmailEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'

export interface TriggerEmailItemProps
  extends EditTriggerItemInterface<TriggerEmailEditInterface> {}

const TriggerEmailItem = ({
  triggers,
  trigger,
  idx,
  errors,
  setRules,
  onDelete,
  isStatic,
}: TriggerEmailItemProps) => (
  <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
    {({ data, onOpen }) => (
      <EmailSelect
        isStatic={isStatic}
        emailData={data.emailData}
        onChange={data =>
          setRules(prev => {
            return {
              ...prev,
              triggers: triggers.map((el, i) => {
                if (idx === i) {
                  return {
                    ...el,
                    mailing: {
                      id: data,
                    },
                  }
                } else {
                  return el
                }
              }),
            }
          })
        }
        errors={errors}
        triggerId={trigger.id}
        mailingId={trigger.mailing.id}
        onOpen={() => onOpen(AutomationRuleTriggersDataEnum.email)}
        subject={trigger.mailing.currentEmail?.subject}
      />
    )}
  </EditTriggerWrapper>
)

export default TriggerEmailItem
