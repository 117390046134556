import { COURSE_API } from 'modules/courses/courses/curriculum/api/course-curriculum-api'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useControlledSwr } from 'shared/hooks/use-controlled-swr'
import useUser from 'shared/hooks/use-user'

export function useCourseCurriculum(id?: CourseId, isFetchAllowed = true) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CourseCurriculumInterface
  >({ method: RequestMethodsEnum.get })

  const {
    data: courseCurriculumData,
    mutate,
    isLoading,
  } = useControlledSwr(
    () => {
      if (user && id) {
        return `${COURSE_API}/${id}/curriculum`
      }
    },
    fetcher,
    undefined,
    isFetchAllowed,
  )
  return { courseCurriculumData, mutate, isLoading }
}
