import { WrapperChildrenPropsInterface } from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { TriggerEmailItemProps } from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-email-item/index'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { AutomationRuleBaseDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import { MailingId } from 'modules/mailing/types/mailing-interface'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface EmailSelectProps
  extends Omit<TriggerEmailItemProps, 'onDelete' | 'triggers' | 'setRules' | 'idx' | 'trigger'>,
    Pick<WrapperChildrenPropsInterface, 'onOpen'> {
  onChange: (data: number) => void
  mailingId?: MailingId
  triggerId: number
  subject?: string
  emailData?: AutomationRuleBaseDataInterface[]
}

const EmailSelect = ({
  errors,
  onOpen,
  onChange,
  mailingId,
  subject,
  triggerId,
  emailData,
}: EmailSelectProps) => {
  const { t } = useLocoTranslation()
  return (
    <FormSelect
      onOpen={() => onOpen(AutomationRuleTriggersDataEnum.email)}
      label={t('global.email')}
      required
      placeholder={t('dashboard.automation_rule.choose_email')}
      value={mailingId}
      staticData={getDataFromObj({
        id: mailingId,
        name: subject,
      })}
      data={emailData}
      onChange={onChange}
      error={errors.automationTriggers[triggerId]?.mailing?.join('')}
    />
  )
}

export default EmailSelect
