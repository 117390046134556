import React from 'react'

function AutomationRuleTriggerIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6209 27.4562C39.9498 26.963 39.4566 26.4698 38.9633 26.4698H30.2495V12.824C30.2495 12.1665 29.2631 11.8375 28.9342 12.495L19.3986 31.5666C19.0696 31.8956 19.3986 32.5531 20.0561 32.5531H28.7699V46.0344C28.7699 46.8566 29.7564 47.0209 30.0853 46.3634L39.6209 27.4562Z"
        fill="#64748B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.429 0C45.7054 0 59.0224 13.1525 59.0224 29.429C59.0224 45.7054 45.7054 59.0224 29.429 59.0224C13.1525 59.0224 0 45.7054 0 29.429C0 13.1525 13.1525 0 29.429 0ZM29.429 3.28828C14.9611 3.28828 3.28839 14.961 3.28839 29.4289C3.28839 43.8967 14.9611 55.7339 29.429 55.7339C43.8968 55.7339 55.734 43.8967 55.734 29.4289C55.734 14.961 43.8968 3.28828 29.429 3.28828Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default AutomationRuleTriggerIcon
