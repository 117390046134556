import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionEmailEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import CreateEmailModal from 'modules/automation-rules/email/components/create-email-modal'
import EditEmailModal from 'modules/automation-rules/email/components/edit-email-modal'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface ActionEmailItemProps extends EditActionItemInterface<ActionEmailEditInterface> {}

const ActionEmailItem = ({
  actions,
  setRules,
  errors,
  idx,
  action,
  onDelete,
}: ActionEmailItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <FormSelect
          onOpen={onOpen}
          htmlFor={'none'}
          labelClassName={'w-full'}
          placeholder={t('dashboard.automation_rule.choose_email')}
          label={renderActionLabel({
            label: t('global.email'),
            additionalLabel: (
              <>
                <CreateEmailModal
                  onSetNewEmail={data =>
                    setRules(prev => {
                      return {
                        ...prev,
                        actions: actions.map((el, i) => {
                          if (idx === i) {
                            return {
                              ...el,
                              systemeEmail: data,
                            }
                          } else {
                            return el
                          }
                        }),
                      }
                    })
                  }
                  mutateSpecificEmailData={data.mutateSpecificEmailData}
                  mutateEmailData={data.mutateEmailData}
                />
                {action.systemeEmail.id && (
                  <EditEmailModal
                    mutateSpecificEmailData={data.mutateSpecificEmailData}
                    mutateEmailData={data.mutateEmailData}
                    emailId={action.systemeEmail.id}
                    onSetNewEmail={data =>
                      setRules(prev => {
                        return {
                          ...prev,
                          actions: actions.map((el, i) => {
                            if (idx === i) {
                              return {
                                ...el,
                                systemeEmail: data,
                              }
                            } else {
                              return el
                            }
                          }),
                        }
                      })
                    }
                  />
                )}
              </>
            ),
          })}
          value={action.systemeEmail.id}
          staticData={getDataFromObj({
            id: action.systemeEmail.id,
            name: action.systemeEmail.subject,
          })}
          data={data.emailData}
          onChange={data =>
            setRules(prev => {
              return {
                ...prev,
                actions: actions.map((el, i) => {
                  if (idx === i) {
                    return { ...el, systemeEmail: { ...action.systemeEmail, id: data } }
                  } else {
                    return el
                  }
                }),
              }
            })
          }
          error={errors.automationActions[action.id]?.systemeEmail?.join('')}
        />
      )}
    </EditActionWrapper>
  )
}

export default ActionEmailItem
