import { triggerItems } from 'modules/automation-rules/triggers/constants/trigger-items'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import React, { useState } from 'react'
import Modal from 'shared/components/modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'

interface AddTriggerModalProps {
  isReady: boolean
  onAddTrigger: (trigger: AutomationRuleTriggersEnum) => void
  allowedTriggers?: AutomationRuleTriggersEnum[]
}

const AddTriggerModal = ({ onAddTrigger, isReady, allowedTriggers }: AddTriggerModalProps) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)

  const filteredTriggers = triggerItems.filter(trigger =>
    allowedTriggers ? allowedTriggers.includes(trigger.slug) : true,
  )

  return (
    <>
      {filteredTriggers.length !== 0 && (
        <button
          disabled={!isReady}
          onClick={() => setOpened(true)}
          className={`outline-none group rounded-full flex self-center ${
            !isReady && 'pointer-events-none'
          }`}
        >
          <AddIcon
            className={`${
              !isReady && 'animate-pulse fill-gray-100/70'
            } main-transition-colors group-hover:fill-blue group-focus-visible:fill-blue fill-gray-100 h-[30px] w-[30px]`}
          />
        </button>
      )}
      <Modal
        title={t('dashboard.automation_rule.edit.add_trigger')}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <div className={'flex flex-col gap-5 px-10'}>
          {filteredTriggers.map((trigger, idx) => (
            <button
              onClick={() => {
                onAddTrigger(trigger.slug)
                setOpened(false)
              }}
              key={idx}
              className={`outline-none w-full text-left group flex gap-3 ${
                idx !== 0 && 'border-t border-gray/30 pt-5'
              }`}
            >
              <div
                className={
                  'w-[40px] [&>svg]:w-[40px] [&>svg]:h-[40x] [&>svg]:main-transition-colors [&>svg]:group-hover:fill-blue [&>svg]:group-focus-visible:fill-blue [&>svg]:group-hover:stroke-blue [&>svg]:group-focus-visible:stroke-blue'
                }
              >
                {trigger.icon}
              </div>
              <div className={'flex gap-1 flex-col'}>
                <span className={'text-darkblue font-bold text-[15px]'}>{t(trigger.title)}</span>
                <span>{t(trigger.description)}</span>
              </div>
            </button>
          ))}
        </div>
      </Modal>
    </>
  )
}

export default AddTriggerModal
