import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import CourseSelect from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-course-item/components/course-select'
import ModuleSelect from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-module-item/components/module-select'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerModuleEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface TriggerModuleItemProps extends EditTriggerItemInterface<TriggerModuleEditInterface> {}

const TriggerModuleItem = ({
  trigger,
  triggers,
  idx,
  errors,
  setRules,
  onDelete,
  isStatic,
}: TriggerModuleItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
      {({ data, renderTriggerLabel, onOpen, onClear }) => (
        <>
          <CourseSelect
            isStatic={isStatic}
            onChange={data => {
              setRules(prev => {
                return {
                  ...prev,
                  triggers: triggers.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        course: { id: data },
                        module: { id: undefined },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
              onClear(AutomationRuleTriggersDataEnum.module)
            }}
            triggerId={trigger.id}
            courseId={trigger.course.id}
            course={trigger.course}
            courseData={data.courseData}
            errors={errors}
            renderTriggerLabel={renderTriggerLabel}
            onOpen={onOpen}
            hint={t('dashboard.automation_rule.trigger.module.course_hint')}
          />

          {trigger.course.id && (
            <ModuleSelect
              isStatic={isStatic}
              hint={t('dashboard.automation_rule.trigger.module.module_hint')}
              triggerId={trigger.id}
              onOpen={onOpen}
              module={trigger.module}
              onChange={data =>
                setRules(prev => {
                  return {
                    ...prev,
                    triggers: triggers.map((el, i) => {
                      if (idx === i) {
                        return {
                          ...el,
                          module: { id: data },
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
              errors={errors}
              moduleId={trigger.module?.id}
              moduleData={data.moduleData}
            />
          )}
        </>
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerModuleItem
