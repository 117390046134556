import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionCommunityEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface ActionCommunityItemProps extends EditActionItemInterface<ActionCommunityEditInterface> {}

const ActionCommunityItem = ({
  actions,
  action,
  errors,
  setRules,
  onDelete,
  idx,
}: ActionCommunityItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <FormSelect
          onOpen={onOpen}
          htmlFor={'none'}
          labelClassName={'w-full'}
          label={renderActionLabel({ label: t('dashboard.communities.title') })}
          placeholder={t(
            'dashboard.funnel.configuration.common.offer_resources.choose_community_access',
          )}
          // TODO remove ? after backend fix
          value={action.community?.id}
          data={data.communitiesData}
          // TODO remove ? after backend fix
          staticData={action.community && getDataFromObj(action.community)}
          onChange={data =>
            setRules(prev => {
              return {
                ...prev,
                actions: actions.map((el, i) => {
                  if (idx === i) {
                    return {
                      ...el,
                      community: { id: data },
                    }
                  } else {
                    return el
                  }
                }),
              }
            })
          }
          error={errors.automationActions[action.id]?.community?.join('')}
        />
      )}
    </EditActionWrapper>
  )
}

export default ActionCommunityItem
