import AddTagSelector from 'modules/automation-rules/actions/components/add-tag-selector'
import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionTagEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import React from 'react'

interface ActionAddTagItemProps extends EditActionItemInterface<ActionTagEditInterface> {}

const ActionAddTagItem = ({
  actions,
  action,
  onDelete,
  setRules,
  errors,
  idx,
}: ActionAddTagItemProps) => (
  <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
    {({ data, onOpen, renderActionLabel }) => (
      <AddTagSelector
        onOpen={onOpen}
        action={action}
        renderActionLabel={renderActionLabel}
        onChange={data =>
          setRules(prev => {
            return {
              ...prev,
              actions: actions.map((el, i) => {
                if (idx === i) {
                  return { ...el, tag: { id: data } }
                } else {
                  return el
                }
              }),
            }
          })
        }
        error={errors.automationActions[action.id]?.tag?.join('')}
        data={data}
      />
    )}
  </EditActionWrapper>
)

export default ActionAddTagItem
