import {
  AutomationRuleErrorInterface,
  AutomationRuleViewInterface,
} from 'modules/automation-rules/types/automation-rule-interface'
import { useState } from 'react'

const defaultErrors: AutomationRuleErrorInterface = {
  automationTriggers: {},
  automationActions: {},
}

const defaultRule = {
  actions: [],
  triggers: [],
  active: true,
}

export const useRuleState = () => {
  const [tempState, setTempState] = useState<AutomationRuleViewInterface>(defaultRule)

  const [errors, setErrors] = useState<AutomationRuleErrorInterface>(defaultErrors)

  const clearState = () => setTempState(defaultRule)

  return { tempState, setTempState, errors, setErrors, clearState }
}
