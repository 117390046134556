import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerCourseBundleEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface TriggerCourseBundleItemProps
  extends EditTriggerItemInterface<TriggerCourseBundleEditInterface> {}

const TriggerCourseBundleItem = ({
  trigger,
  triggers,
  errors,
  idx,
  onDelete,
  setRules,
  isStatic,
}: TriggerCourseBundleItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderTriggerLabel }) => (
        <>
          <FormSelect
            disabled={isStatic}
            onOpen={() => onOpen(AutomationRuleTriggersDataEnum.enrolled_in_course_bundle)}
            htmlFor={'none'}
            label={renderTriggerLabel({
              label: t('dashboard.course_bundle.title_one'),
              additionalLabel: trigger.courseBundle.id ? (
                <LinkWithoutPrefetch
                  href={`/course-bundles/${trigger.courseBundle.id}`}
                  rel="noreferrer"
                  target={'_blank'}
                  className={`outline-none group self-center`}
                >
                  <OpenInNewIcon
                    className={
                      'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'
                    }
                  />
                </LinkWithoutPrefetch>
              ) : undefined,
            })}
            placeholder={t('dashboard.automation_rule.choose_course_bundle')}
            value={trigger.courseBundle.id}
            staticData={getDataFromObj(trigger.courseBundle)}
            data={data.courseBundleData}
            onChange={data =>
              setRules(prev => {
                return {
                  ...prev,
                  triggers: triggers.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        courseBundle: { id: data },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
            }
            error={errors.automationTriggers[trigger.id]?.courseBundle?.join('')}
          />
        </>
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerCourseBundleItem
