import { defaultTriggerValues } from 'modules/automation-rules/triggers/constants/default-trigger-values'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { AutomationRulesTriggersEditType } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import React from 'react'

export const getDefaultTriggerValue = (
  slug: AutomationRuleTriggersEnum,
  maxTriggerId: React.MutableRefObject<number>,
): AutomationRulesTriggersEditType => {
  const defaultTrigger = { ...defaultTriggerValues[slug] }
  defaultTrigger.id = ++maxTriggerId.current
  return defaultTrigger
}
