import { actionItems } from 'modules/automation-rules/actions/constants/action-items'
import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import React, { useState } from 'react'
import Modal from 'shared/components/modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import AddIcon from 'shared/icons/add-icon'

interface AddActionModalProps {
  isReady: boolean
  onAddAction: (action: AutomationRuleActionsEnum) => void
}

const AddActionModal = ({ isReady, onAddAction }: AddActionModalProps) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const { user, isRootAdmin } = useUser()
  return (
    <>
      <button
        disabled={!isReady}
        onClick={() => setOpened(true)}
        className={`outline-none group rounded-full flex self-center ${
          !isReady && 'pointer-events-none'
        }`}
      >
        <AddIcon
          className={`${
            !isReady && 'animate-pulse fill-gray-100/70'
          } main-transition-colors group-hover:fill-blue group-focus-visible:fill-blue fill-gray-100 h-[30px] w-[30px]`}
        />
      </button>
      <Modal
        title={t('dashboard.automation_rule.edit.add_action')}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <div className={'flex flex-col gap-5 px-10'}>
          {actionItems
            .filter(el => {
              if (!user) return false
              if (!isRootAdmin) {
                return el.slug !== AutomationRuleActionsEnum.create_user
              } else {
                return true
              }
            })
            .map((action, idx) => (
              <button
                onClick={() => {
                  onAddAction(action.slug)
                  setOpened(false)
                }}
                key={idx}
                className={`outline-none w-full text-left group flex gap-3 ${
                  idx !== 0 && 'border-t border-gray/30 pt-5'
                }`}
              >
                <div
                  className={
                    'w-[40px] [&>svg]:w-[40px] [&>svg]:h-[40x] [&>svg]:main-transition-colors [&>svg]:group-hover:fill-blue [&>svg]:group-focus-visible:fill-blue'
                  }
                >
                  {action.icon}
                </div>
                <div className={'flex gap-1 flex-col'}>
                  <span className={'text-darkblue font-bold text-[15px]'}>{t(action.title)}</span>
                  <span>{t(action.description)}</span>
                </div>
              </button>
            ))}
        </div>
      </Modal>
    </>
  )
}

export default AddActionModal
