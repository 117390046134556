import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerCommunityEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface TriggerCampaignItemProps
  extends EditTriggerItemInterface<TriggerCommunityEditInterface> {}

const TriggerCommunityItem = ({
  triggers,
  trigger,
  idx,
  errors,
  setRules,
  onDelete,
  isStatic,
}: TriggerCampaignItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
      {({ data, onOpen }) => (
        <>
          <FormSelect
            disabled={isStatic}
            onOpen={() => onOpen(AutomationRuleTriggersDataEnum.enrolled_in_community)}
            htmlFor={'none'}
            label={t('dashboard.community.title')}
            required
            placeholder={t('dashboard.automation_rule.choose_community')}
            // TODO remove ? after backend fix
            value={trigger.community?.id}
            staticData={trigger.community && getDataFromObj(trigger.community)}
            data={data.communityData}
            onChange={data =>
              setRules(prev => {
                return {
                  ...prev,
                  triggers: triggers.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        community: { id: data },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
            }
            error={errors.automationTriggers[trigger.id]?.community?.join('')}
          />
        </>
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerCommunityItem
