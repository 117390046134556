import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionSpecEmailEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import CreateEmailModal from 'modules/automation-rules/email/components/create-email-modal'
import EditEmailModal from 'modules/automation-rules/email/components/edit-email-modal'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface ActionSpecEmailItemProps extends EditActionItemInterface<ActionSpecEmailEditInterface> {}

const ActionSpecEmailItem = ({
  actions,
  setRules,
  action,
  onDelete,
  idx,
  errors,
}: ActionSpecEmailItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <FormSelect
          onOpen={onOpen}
          htmlFor={'none'}
          labelClassName={'w-full'}
          placeholder={t('dashboard.automation_rule.choose_email')}
          label={renderActionLabel({
            label: t('global.email'),
            additionalLabel: (
              <>
                <CreateEmailModal
                  onSetNewEmail={data => {
                    onOpen()
                    setRules(prev => {
                      return {
                        ...prev,
                        actions: actions.map((el, i) => {
                          if (idx === i) {
                            return {
                              ...el,
                              emailMessageWithRecipient: {
                                id: data.id,
                                mailing: {
                                  currentEmail: { subject: data.subject },
                                },
                              },
                            }
                          } else {
                            return el
                          }
                        }),
                      }
                    })
                  }}
                  mutateSpecificEmailData={data.mutateSpecificEmailData}
                  mutateEmailData={data.mutateEmailData}
                  withRecipient
                />
                {action.emailMessageWithRecipient.id && (
                  <EditEmailModal
                    mutateEmailData={data.mutateEmailData}
                    withRecipient
                    mutateSpecificEmailData={data.mutateSpecificEmailData}
                    emailId={action.emailMessageWithRecipient.id}
                  />
                )}
              </>
            ),
          })}
          value={action.emailMessageWithRecipient.id}
          staticData={getDataFromObj({
            id: action.emailMessageWithRecipient.id,
            name: action.emailMessageWithRecipient.mailing?.currentEmail.subject,
          })}
          data={data.specificEmailData}
          onChange={data =>
            setRules(prev => {
              return {
                ...prev,
                actions: actions.map((el, i) => {
                  if (idx === i) {
                    return {
                      ...el,
                      emailMessageWithRecipient: {
                        id: data,
                      },
                    }
                  } else {
                    return el
                  }
                }),
              }
            })
          }
          error={errors.automationActions[action.id || idx]?.emailMessageWithRecipient?.join('')}
        />
      )}
    </EditActionWrapper>
  )
}

export default ActionSpecEmailItem
