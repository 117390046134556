import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import { ActionEditType } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { AccessTypeEnum } from 'modules/courses/courses/curriculum/enums/access-type-enum'

export const defaultActionValues: Record<AutomationRuleActionsEnum, ActionEditType> = {
  [AutomationRuleActionsEnum.send_email]: {
    type: AutomationRuleActionsEnum.send_email,
    systemeEmail: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.add_tag]: {
    type: AutomationRuleActionsEnum.add_tag,
    tag: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.remove_tag]: {
    type: AutomationRuleActionsEnum.remove_tag,
    tag: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.send_webhook]: {
    type: AutomationRuleActionsEnum.send_webhook,
    webhookUrl: '',
    id: 0,
  },
  [AutomationRuleActionsEnum.enroll_in_course]: {
    type: AutomationRuleActionsEnum.enroll_in_course,
    accessType: AccessTypeEnum.fullAccess,
    course: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.revoke_access_to_course]: {
    type: AutomationRuleActionsEnum.revoke_access_to_course,
    accessType: AccessTypeEnum.fullAccess,
    course: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.subscribe_to_campaign]: {
    type: AutomationRuleActionsEnum.subscribe_to_campaign,
    campaign: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.unsubscribe_from_campaign]: {
    type: AutomationRuleActionsEnum.unsubscribe_from_campaign,
    campaign: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.create_user]: {
    type: AutomationRuleActionsEnum.create_user,
    locale: undefined,
    id: 0,
  },
  [AutomationRuleActionsEnum.send_email_to_specific_address]: {
    type: AutomationRuleActionsEnum.send_email_to_specific_address,
    emailMessageWithRecipient: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.grant_access_to_community]: {
    type: AutomationRuleActionsEnum.grant_access_to_community,
    community: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.revoke_access_to_community]: {
    type: AutomationRuleActionsEnum.revoke_access_to_community,
    community: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.enroll_in_course_bundle]: {
    type: AutomationRuleActionsEnum.enroll_in_course_bundle,
    enrollmentAccessType: AccessTypeEnum.fullAccess,
    courseBundle: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.revoke_access_to_course_bundle]: {
    type: AutomationRuleActionsEnum.revoke_access_to_course_bundle,
    courseBundle: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.assign_to_pipeline_stage]: {
    type: AutomationRuleActionsEnum.assign_to_pipeline_stage,
    pipelineStage: { id: undefined },
    id: 0,
  },
}
