import React from 'react'

function SendEmailIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0855 14.5284C26.3503 14.1908 26.317 13.7074 26.0075 13.41C25.6981 13.1126 25.2138 13.0981 24.8871 13.3762L9.90817 26.1196L1.99827 23.0607C1.53692 22.8838 1.16117 22.537 0.949013 22.091C0.736423 21.6453 0.703483 21.1345 0.856983 20.6652C1.01048 20.1958 1.33884 19.8036 1.77364 19.5694L34.612 1.17392C34.9576 0.987858 35.3595 0.933618 35.742 1.02161C36.125 1.10921 36.4625 1.33345 36.6924 1.65173C36.9223 1.97041 37.0284 2.36142 36.9914 2.75241L34.1108 32.7791C34.0522 33.4036 33.7025 33.9639 33.1672 34.2905C32.6316 34.6172 31.9737 34.6722 31.3914 34.4384L21.7648 30.7111L15.7212 36.3742C15.4222 36.6535 15.0255 36.8046 14.6165 36.7941C14.207 36.7841 13.8184 36.6133 13.5339 36.3195C13.249 36.0254 13.0914 35.6312 13.0942 35.2221V30.7747L26.0855 14.5284Z"
      />
    </svg>
  )
}

export default SendEmailIcon
