import {
  AutomationRuleActionsDataEnum,
  AutomationRuleActionsEnum,
} from 'modules/automation-rules/actions/enums/action-enum'

export const getActionDataFromSlug = (
  slug: AutomationRuleActionsEnum,
): AutomationRuleActionsDataEnum => {
  switch (slug) {
    case AutomationRuleActionsEnum.add_tag:
    case AutomationRuleActionsEnum.remove_tag:
      return AutomationRuleActionsDataEnum.tag

    case AutomationRuleActionsEnum.enroll_in_course_bundle:
    case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
      return AutomationRuleActionsDataEnum.course_bundle

    case AutomationRuleActionsEnum.subscribe_to_campaign:
    case AutomationRuleActionsEnum.unsubscribe_from_campaign:
      return AutomationRuleActionsDataEnum.campaign

    case AutomationRuleActionsEnum.send_email:
      return AutomationRuleActionsDataEnum.mailing

    case AutomationRuleActionsEnum.send_email_to_specific_address:
      return AutomationRuleActionsDataEnum.specific_mailing

    case AutomationRuleActionsEnum.enroll_in_course:
    case AutomationRuleActionsEnum.revoke_access_to_course:
      return AutomationRuleActionsDataEnum.course

    case AutomationRuleActionsEnum.grant_access_to_community:
    case AutomationRuleActionsEnum.revoke_access_to_community:
      return AutomationRuleActionsDataEnum.community

    case AutomationRuleActionsEnum.send_webhook:
      return AutomationRuleActionsDataEnum.webhook

    case AutomationRuleActionsEnum.assign_to_pipeline_stage:
      return AutomationRuleActionsDataEnum.pipeline

    case AutomationRuleActionsEnum.create_user:
      return AutomationRuleActionsDataEnum.locale
  }
}
