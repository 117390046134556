import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionWebhookEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import React from 'react'
import FormInput from 'shared/components/form-input'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface ActionWebhookItemProps extends EditActionItemInterface<ActionWebhookEditInterface> {}

const ActionWebhookItem = ({
  actions,
  action,
  errors,
  setRules,
  onDelete,
  idx,
}: ActionWebhookItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ renderActionLabel }) => (
        <FormInput
          nativeLabelProps={{ htmlFor: 'none' }}
          labelClassName={'w-full'}
          label={renderActionLabel({ label: t('dashboard.automation_rule.webhook_url') })}
          placeholder={t('dashboard.automation_rule.type_webhook')}
          value={action.webhookUrl}
          onChange={e =>
            setRules(prev => {
              return {
                ...prev,
                actions: actions.map((el, i) => {
                  if (idx === i) {
                    return { ...el, webhookUrl: e.target.value }
                  } else {
                    return el
                  }
                }),
              }
            })
          }
          error={errors.automationActions[action.id]?.webhookUrl?.join('')}
        />
      )}
    </EditActionWrapper>
  )
}

export default ActionWebhookItem
