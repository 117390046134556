import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { AUTOMATION_RULES_API } from '../api/automation-rules-api'
import { AutomationRuleViewInterface, RuleId } from '../types/automation-rule-interface'

export const useSaveRule = () => {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.put,
    AutomationRuleViewInterface
  >({
    method: RequestMethodsEnum.put,
    showFieldsErrorToast: true,
  })
  return {
    fetcher: async (id: RuleId, data: string) => {
      try {
        return await fetcher(`${AUTOMATION_RULES_API}/${id}`, data, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
      } catch (e) {
        throw e
      }
    },
  }
}
