import {
  BlogEntityEnum,
  BlogEntityTitleEnum,
  BlogPageEnum,
} from 'modules/automation-rules/enums/automation-rule-enum'
import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerBlogEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { AutomationRuleBlogEntityDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import {
  getBlogEntityId,
  getBlogEntityValue,
  getDataFromObj,
} from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'
import { getBlogPostNextjsUrl } from 'shared/utils/get-blog-post-nextjs-url'

interface TriggerBlogItemProps extends EditTriggerItemInterface<TriggerBlogEditInterface> {}

const TriggerBlogItem = ({
  triggers,
  trigger,
  idx,
  onDelete,
  setRules,
  errors,
  isStatic,
}: TriggerBlogItemProps) => {
  const { t } = useLocoTranslation()

  const blogEntityData: AutomationRuleBlogEntityDataInterface[] = [
    { id: BlogEntityEnum.blog_page, caption: t(BlogEntityTitleEnum.blog_page) },
    { id: BlogEntityEnum.blog_post_body, caption: t(BlogEntityTitleEnum.blog_post_body) },
    { id: BlogEntityEnum.blog_post_layout, caption: t(BlogEntityTitleEnum.blog_post_layout) },
    { id: BlogEntityEnum.blog_layout, caption: t(BlogEntityTitleEnum.blog_layout) },
  ]
  return (
    <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
      {({ renderTriggerLabel, data, onOpen, onClear }) => (
        <>
          <FormSelect
            disabled={isStatic}
            onOpen={() => onOpen(AutomationRuleTriggersDataEnum.blog_page_form_subscribed)}
            htmlFor={'none'}
            label={renderTriggerLabel({
              label: t('dashboard.blog.title'),
              additionalLabel: trigger.blog.id ? (
                <LinkWithoutPrefetch
                  href={getBlogPostNextjsUrl(trigger.blog.id)}
                  className={`outline-none group self-center`}
                  target={'_blank'}
                >
                  <OpenInNewIcon
                    className={
                      'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'
                    }
                  />
                </LinkWithoutPrefetch>
              ) : undefined,
            })}
            placeholder={t('dashboard.automation_rule.choose_blog')}
            value={trigger.blog.id}
            staticData={getDataFromObj(trigger.blog)}
            data={data.blogData}
            onChange={data => {
              setRules(prev => {
                return {
                  ...prev,
                  triggers: triggers.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        blog: { id: data },
                        page: { type: undefined, id: undefined },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
              onClear(AutomationRuleTriggersDataEnum.blog_page_form_subscribed_page)
              onClear(AutomationRuleTriggersDataEnum.blog_page_form_subscribed_post)
            }}
            error={errors.automationTriggers[trigger.id]?.blog?.join('')}
          />
          {trigger.blog.id && (
            <FormSelect
              disabled={isStatic}
              onOpen={() => onOpen(AutomationRuleTriggersDataEnum.blog_page_form_subscribed)}
              label={t('dashboard.automation_rule.edit.blog_entity')}
              placeholder={t('dashboard.automation_rule.choose_blog_entity')}
              staticData={[
                {
                  id: getBlogEntityValue(trigger.page.type),
                  caption: t(
                    BlogEntityTitleEnum[trigger.page.type as keyof typeof BlogEntityTitleEnum],
                  ),
                },
              ]}
              required
              value={getBlogEntityValue(trigger.page.type)}
              data={data.blogData ? blogEntityData : undefined}
              onChange={newData =>
                setRules(prev => {
                  return {
                    ...prev,
                    triggers: triggers.map((el, i) => {
                      if (idx === i) {
                        if (data.blogEntityData && trigger.blog.id) {
                          return {
                            ...el,
                            page: {
                              type: newData,
                              id: getBlogEntityId(data.blogEntityData, trigger.blog.id, newData),
                            },
                          }
                        } else {
                          return {
                            ...el,
                            page: { type: newData, id: undefined },
                          }
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
              error={errors.automationTriggers[trigger.id]?.blog_entity?.join('')}
            />
          )}
          {(trigger.page.type === BlogEntityEnum.blog_page ||
            trigger.page.type === BlogPageEnum.blog_home ||
            trigger.page.type === BlogPageEnum.blog_static) &&
            trigger.blog.id && (
              <FormSelect
                disabled={isStatic}
                onOpen={() => onOpen(AutomationRuleTriggersDataEnum.blog_page_form_subscribed_page)}
                label={t('dashboard.automation_rule.edit.choose_page')}
                placeholder={t('dashboard.automation_rule.choose_blog_page')}
                required
                value={trigger.page.id}
                staticData={getDataFromObj({
                  id: trigger.page.id,
                  name: trigger.page.blogPage?.name,
                })}
                data={data.blogPagesData}
                onChange={data =>
                  setRules(prev => {
                    return {
                      ...prev,
                      triggers: triggers.map((el, i) => {
                        if (idx === i) {
                          return {
                            ...el,
                            page: { type: trigger.page.type, id: data },
                          }
                        } else {
                          return el
                        }
                      }),
                    }
                  })
                }
                error={errors.automationTriggers[trigger.id]?.page?.join('')}
              />
            )}
          {trigger.page.type === BlogEntityEnum.blog_post_body && trigger.blog.id && (
            <FormSelect
              disabled={isStatic}
              onOpen={() => onOpen(AutomationRuleTriggersDataEnum.blog_page_form_subscribed_post)}
              label={t('dashboard.automation_rule.edit.choose_page')}
              placeholder={t('dashboard.automation_rule.choose_blog_post')}
              required
              value={trigger.page.id}
              staticData={getDataFromObj({
                id: trigger.page.id,
                name: trigger.page.blogPost?.name,
              })}
              data={data.blogPostsData}
              onChange={data =>
                setRules(prev => {
                  return {
                    ...prev,
                    triggers: triggers.map((el, i) => {
                      if (idx === i) {
                        return {
                          ...el,
                          page: { type: trigger.page.type, id: data },
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
              error={errors.automationTriggers[trigger.id]?.page?.join('')}
            />
          )}
        </>
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerBlogItem
