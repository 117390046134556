import {
  updateRuleEmail,
  updateRuleSpecificEmail,
} from 'modules/automation-rules/email/api/rule-email-api'
import { RuleSpecificEmailApiInterface } from 'modules/automation-rules/email/types/rule-email-interface'
import { FilterEmailApiInterface } from 'modules/automation-rules/types/automation-rule-filter-interface'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import DashboardMailEditor from 'shared/components/dashboard-mail-editor'
import {
  updateAttachments,
  updateAttachmentsWithRecipient,
} from 'shared/components/dashboard-mail-editor/api/email-api'
import { useEmail } from 'shared/components/dashboard-mail-editor/hooks/use-email'
import { useEmailWithRecipient } from 'shared/components/dashboard-mail-editor/hooks/use-email-with-recipient'
import { useUnsavedEmail } from 'shared/components/dashboard-mail-editor/hooks/use-unsaved-email'
import {
  AttachmentFileInterface,
  EmailBaseInterface,
  EmailErrorResponseContentInterface,
  EmailErrorType,
  EmailId,
  EmailRecipientErrorResponseContentInterface,
  EmailRecipientInterface,
  MailingApiInterface,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import { getStyledBody } from 'shared/components/dashboard-mail-editor/utils/get-styled-body'
import Modal, { ModalSizeEnum } from 'shared/components/modal'
import { Tooltip } from 'shared/components/tooltip'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import PenIcon from 'shared/icons/pen-icon'
import { isValidEmail } from 'shared/utils/email-validation'
import { KeyedMutator } from 'swr'

const defaultErrors: EmailErrorType = {
  body: '',
  fromEmail: '',
  fromName: '',
  subject: '',
  previewText: '',
  common: [],
}

interface RuleEmailEditModalProps {
  withRecipient?: boolean
  emailId: EmailId
  mutateEmailData: KeyedMutator<FilterEmailApiInterface[]> | undefined
  mutateSpecificEmailData: KeyedMutator<FilterEmailApiInterface[]> | undefined
  onSetNewEmail?: (data: { id: number; subject: string }) => void
}

const RuleEmailEditModal = ({
  withRecipient,
  emailId,
  mutateEmailData,
  mutateSpecificEmailData,
  onSetNewEmail,
}: RuleEmailEditModalProps) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [shouldFetch, setShouldFetch] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const { data: ruleEmail, mutate: mutateEmail } = useEmail(emailId, !withRecipient && shouldFetch)
  const { data: ruleEmailWithRecipient, mutate: mutateEmailWithRecipient } = useEmailWithRecipient(
    emailId,
    !!withRecipient && shouldFetch,
  )

  const [tempState, setTempState] = useState<EmailRecipientInterface>()
  const [errors, setErrors] = useState<EmailErrorType>(defaultErrors)

  useEffect(() => {
    if (ruleEmail) {
      setTempState(prev => ({
        ...prev,
        body: ruleEmail.currentEmail.body,
        subject: ruleEmail.currentEmail.subject,
        previewText: ruleEmail.currentEmail.previewText,
        fromEmail: ruleEmail.currentEmail.fromEmail,
        fromName: ruleEmail.currentEmail.fromName,
        recipient: '',
      }))
      setAttachmentsTempState(ruleEmail.currentEmail.attachments)
    }
  }, [ruleEmail])

  useEffect(() => {
    if (ruleEmailWithRecipient) {
      setTempState(prev => ({
        ...prev,
        recipient: ruleEmailWithRecipient.recipient,
        body: ruleEmailWithRecipient.currentEmail.body,
        subject: ruleEmailWithRecipient.currentEmail.subject,
        previewText: ruleEmailWithRecipient.currentEmail.previewText,
        fromEmail: ruleEmailWithRecipient.currentEmail.fromEmail,
        fromName: ruleEmailWithRecipient.currentEmail.fromName,
      }))
      setAttachmentsTempState(ruleEmailWithRecipient.currentEmail.attachments)
    }
  }, [ruleEmailWithRecipient])

  const [attachmentsTempState, setAttachmentsTempState] = useState<
    (AttachmentFileInterface | File)[]
  >([])

  const onSave = async () => {
    try {
      if (tempState) {
        setErrors(defaultErrors)
        if (!isValidEmail(tempState.fromEmail)) {
          return setErrors(prev => ({
            ...prev,
            fromEmail: t('dashboard.validation.email_not_valid'),
          }))
        }
        if (withRecipient && !isValidEmail(tempState.recipient)) {
          return setErrors(prev => ({
            ...prev,
            recipient: t('dashboard.validation.email_not_valid'),
          }))
        }
        setIsFetching(true)
        const currentEmail: EmailBaseInterface = {
          fromEmail: tempState.fromEmail,
          fromName: tempState.fromName,
          subject: tempState.subject,
          previewText: tempState.previewText,
          body: getStyledBody(tempState.body),
        }

        const attachmentsResponse = await (withRecipient
          ? updateAttachmentsWithRecipient(emailId, attachmentsTempState)
          : updateAttachments(emailId, attachmentsTempState))
        const emailResponse = await (withRecipient
          ? updateRuleSpecificEmail(emailId, {
              recipientAddress: tempState.recipient,
              mailing: {
                currentEmail,
              },
            })
          : updateRuleEmail(emailId, currentEmail))

        if (withRecipient) {
          const response = emailResponse.data as RuleSpecificEmailApiInterface
          await mutateEmailWithRecipient(data => {
            if (data) {
              return {
                ...data,
                recipient: response.recipientAddress,
                currentEmail: {
                  ...response.mailing.currentEmail,
                  fromEmail: response.mailing.currentEmail.fromEmail,
                  fromName: response.mailing.currentEmail.fromName,
                  subject: response.mailing.currentEmail.subject,
                  previewText: response.mailing.currentEmail.previewText,
                  body: response.mailing.currentEmail.body,
                  attachments: attachmentsResponse.data.attachments,
                },
              }
            }
          }, false)
          if (mutateSpecificEmailData) {
            await mutateSpecificEmailData(data => {
              if (data) {
                return data.map(el => {
                  if (el.id === response.id) {
                    el = { id: el.id, subject: response.mailing.currentEmail.subject }
                  }
                  return el
                })
              }
            }, false)
          }
        } else {
          const response = emailResponse.data as MailingApiInterface
          let newData: EmailBaseInterface
          if (response.currentEmail) {
            newData = response.currentEmail
          } else {
            newData = { ...currentEmail }
          }
          if (mutateEmailData) {
            await mutateEmailData(data => {
              if (data) {
                return data.map(el => {
                  if (el.id === ruleEmail?.currentEmail.id) {
                    el = { id: response.currentEmail.id, subject: newData.subject }
                  }
                  return el
                })
              }
            }, false)
          }
          await mutateEmail(data => {
            if (data) {
              return {
                ...data,
                currentEmail: {
                  id: response.currentEmail.id,
                  fromEmail: newData.fromEmail,
                  fromName: newData.fromName,
                  subject: newData.subject,
                  previewText: newData.previewText,
                  body: newData.body,
                  attachments: attachmentsResponse.data.attachments,
                },
              }
            }
          }, false)
          if (onSetNewEmail) {
            onSetNewEmail({
              id: response.currentEmail.id,
              subject: response.currentEmail.subject,
            })
          }
        }
        toast.success(t('global.changes_saved'))
        setIsFetching(false)
        setOpened(false)
        await removeDbRecord()
      }
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (withRecipient) {
          const errors = e.errors as EmailRecipientErrorResponseContentInterface
          if (errors.fields) {
            if (errors.fields.recipientAddress) {
              setErrors(prev => ({
                ...prev,
                recipient: errors.fields.recipientAddress?.join(''),
              }))
            }

            const currentEmail = errors.fields.mailing?.currentEmail
            if (currentEmail) {
              setErrors(prev => ({
                ...prev,
                body: currentEmail.body?.join(''),
                fromEmail: currentEmail.fromEmail?.join(''),
                fromName: currentEmail.fromName?.join(''),
                subject: currentEmail.subject?.join(''),
                previewText: currentEmail.previewText?.join(''),
              }))
            }
          }
        } else {
          const errors = e.errors as EmailErrorResponseContentInterface
          if (errors.fields) {
            const fields = errors.fields as unknown as Record<keyof EmailBaseInterface, string[]>
            setErrors(prev => ({
              ...prev,
              body: fields.body?.join(''),
              fromEmail: fields.fromEmail?.join(''),
              fromName: fields.fromName?.join(''),
              subject: fields.subject?.join(''),
              previewText: fields.previewText?.join(''),
            }))
          }
        }
        if (e.errors.common) {
          const commonErrors = e.errors.common
          setErrors(prev => {
            return {
              ...prev,
              common: commonErrors,
            }
          })
        }
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const { setCkeditorRef, CkeditorUnsavedEmail, removeDbRecord, setNeedToShowModal } =
    useUnsavedEmail({
      mailingId: withRecipient ? ruleEmailWithRecipient?.id : ruleEmail?.id,
      currentBody: tempState?.body,
      initialBody: withRecipient
        ? ruleEmailWithRecipient?.currentEmail.body
        : ruleEmail?.currentEmail.body,
    })

  const onChangeEmail = (value: EmailRecipientInterface) => setTempState(value)

  const onOpen = () => {
    setOpened(true)
    setShouldFetch(true)
    setNeedToShowModal(true)
  }

  const afterEnter = async () => {
    await mutateEmail()
    await mutateEmailWithRecipient()
  }

  return (
    <>
      <Tooltip mode={'hover'} label={t('dashboard.automation_rule.edit.edit_email')}>
        <button onClick={onOpen} className={'group outline-none'}>
          <PenIcon
            className={
              'fill-gray-100 main-transition-colors group-hover:fill-blue group-focus-visible:fill-blue w-[16px] h-[16px]'
            }
          />
        </button>
      </Tooltip>
      <Modal
        afterEnter={afterEnter}
        afterLeave={() => {
          setErrors(defaultErrors)
          setShouldFetch(false)
        }}
        isFetching={isFetching}
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('dashboard.automation_rule.edit.edit_email')}
        size={ModalSizeEnum.big}
      >
        <form className="flex flex-col gap-6 lg:gap-10">
          <DashboardMailEditor
            initValue={
              withRecipient
                ? ruleEmailWithRecipient?.currentEmail.body
                : ruleEmail?.currentEmail.body
            }
            withRecipient={withRecipient}
            inModal
            isPreFetching={withRecipient ? !ruleEmailWithRecipient : !ruleEmail}
            tempState={tempState}
            onChangeEmail={onChangeEmail}
            attachmentsTempState={attachmentsTempState}
            setAttachmentsTempState={setAttachmentsTempState}
            errors={errors}
            setCkeditorRef={setCkeditorRef}
          />
          <div className={'flex justify-center'}>
            <Tooltip
              mode="hover"
              label={t('dashboard.email.warning.update_automation_workflow_email')}
              disabled={withRecipient ? !ruleEmailWithRecipient : !ruleEmail}
            >
              <PrimaryButton
                disabled={withRecipient ? !ruleEmailWithRecipient : !ruleEmail}
                width="large"
                type={'submit'}
                onClick={async e => {
                  e.preventDefault()
                  onSave()
                }}
                isFetching={isFetching}
              >
                {t('global.save')}
              </PrimaryButton>
            </Tooltip>
          </div>
        </form>
        {CkeditorUnsavedEmail}
      </Modal>
    </>
  )
}

export default RuleEmailEditModal
