import React from 'react'

function PenIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.64524 22.7014C0.46938 22.7433 0.28417 22.6914 0.15575 22.5642C0.0270098 22.4371 -0.0268802 22.2528 0.0128098 22.0764L1.1728 16.8362L5.76534 21.452L0.64524 22.7014ZM7.00918 20.2033L2.41699 15.5866L14.0449 3.89916L18.64 8.51589L7.00918 20.2033ZM22.1262 5.01601L19.8369 7.31635L15.2418 2.69962L17.2043 0.727479C17.5844 0.345429 18.1013 0.130859 18.6402 0.130859C19.1793 0.130859 19.6959 0.345429 20.0764 0.727479L22.1262 2.78642C22.4212 3.0823 22.587 3.48338 22.587 3.90123C22.587 4.3194 22.4212 4.72014 22.1262 5.01602V5.01601Z"
      />
    </svg>
  )
}

export default PenIcon
