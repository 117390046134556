import { WrapperChildrenPropsInterface } from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { TriggerCourseItemProps } from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-course-item/index'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerCourseEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { AutomationRuleBaseDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import { getCourseCurriculumUrl } from 'modules/courses/courses/curriculum/utils/get-course-curriculum-url'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { Hint } from 'shared/components/hint'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface CourseSelectProps
  extends Omit<TriggerCourseItemProps, 'onDelete' | 'triggers' | 'setRules' | 'idx' | 'trigger'>,
    Pick<WrapperChildrenPropsInterface, 'renderTriggerLabel' | 'onOpen'> {
  onChange: (data: number) => void
  courseId?: CourseId
  triggerId: number
  course: TriggerCourseEditInterface['course']
  courseData?: AutomationRuleBaseDataInterface[]
  hint?: string
}

const CourseSelect = ({
  errors,
  renderTriggerLabel,
  onOpen,
  onChange,
  courseId,
  triggerId,
  courseData,
  course,
  hint,
  isStatic,
}: CourseSelectProps) => {
  const { t } = useLocoTranslation()
  return (
    <FormSelect
      disabled={isStatic}
      onOpen={() => onOpen(AutomationRuleTriggersDataEnum.course)}
      htmlFor={'none'}
      label={renderTriggerLabel({
        label: t('dashboard.courses.title'),
        additionalLabel: (
          <div className={'flex items-center gap-1'}>
            {courseId && (
              <LinkWithoutPrefetch
                href={getCourseCurriculumUrl({ courseId })}
                rel="noreferrer"
                target={'_blank'}
                className={`outline-none group self-center`}
              >
                <OpenInNewIcon
                  className={'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'}
                />
              </LinkWithoutPrefetch>
            )}
            {hint && <Hint label={hint} />}
          </div>
        ),
      })}
      placeholder={t('dashboard.automation_rule.choose_course')}
      value={courseId}
      staticData={getDataFromObj(course)}
      data={courseData}
      onChange={onChange}
      error={errors.automationTriggers[triggerId]?.course?.join('')}
    />
  )
}

export default CourseSelect
