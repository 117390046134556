import {
  actionDescriptionLocoKeys,
  actionTitleLocoKeys,
} from 'modules/automation-rules/actions/constants/action-loco-keys'
import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import { getActionIcon } from 'modules/automation-rules/actions/utils/get-action-icon'

interface ActionItem {
  slug: AutomationRuleActionsEnum
  title: string
  description: string
  icon: JSX.Element
}

export const actionItems: ActionItem[] = Object.values(AutomationRuleActionsEnum).map(slug => ({
  slug: slug,
  title: actionTitleLocoKeys[slug],
  description: actionDescriptionLocoKeys[slug],
  icon: getActionIcon(slug),
}))
