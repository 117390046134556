import {
  triggerActionLocoKeys,
  triggerDescriptionLocoKeys,
} from 'modules/automation-rules/triggers/constants/trigger-loco-keys'
import {
  AutomationRuleTriggersDataEnum,
  AutomationRuleTriggersEnum,
} from 'modules/automation-rules/triggers/enums/trigger-enum'
import {
  TriggersDataInterface,
  useTriggersData,
} from 'modules/automation-rules/triggers/hooks/use-triggers-data'
import { AutomationRulesTriggersEditType } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getTriggerIcon } from 'modules/automation-rules/triggers/utils/get-trigger-icon'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'

export interface WrapperChildrenPropsInterface {
  renderTriggerLabel: ({
    label,
    additionalLabel,
  }: {
    label: string
    additionalLabel?: JSX.Element
  }) => JSX.Element
  onOpen: (slug: AutomationRuleTriggersDataEnum) => void
  onClear: (slug: AutomationRuleTriggersDataEnum) => void
  data: TriggersDataInterface
}
type ChildrenType = (args: WrapperChildrenPropsInterface) => JSX.Element

interface RuleTriggerWrapperProps {
  trigger: AutomationRulesTriggersEditType
  children: ChildrenType
  onDelete: () => void
}

const RuleTriggerWrapper = ({ trigger, children, onDelete }: RuleTriggerWrapperProps) => {
  const { t } = useLocoTranslation()

  const blogId =
    trigger.type === AutomationRuleTriggersEnum.blog_page_form_subscribed
      ? trigger.blog.id
      : undefined

  const blogEntity =
    trigger.type === AutomationRuleTriggersEnum.blog_page_form_subscribed
      ? trigger.page.type
      : undefined

  const formSubscribeFunnelId =
    trigger.type === AutomationRuleTriggersEnum.form_subscribed
      ? trigger.funnelStep.funnel.id
      : undefined

  const newSaleFunnelId =
    trigger.type === AutomationRuleTriggersEnum.new_sale ? trigger.funnelStep.funnel.id : undefined

  const cancelSaleFunnelId =
    trigger.type === AutomationRuleTriggersEnum.sale_canceled
      ? trigger.funnelStep.funnel.id
      : undefined

  const pageVisitedFunnelId =
    trigger.type === AutomationRuleTriggersEnum.page_visited
      ? trigger.funnelStep.funnel.id
      : undefined

  const webinarFunnelId =
    trigger.type === AutomationRuleTriggersEnum.registered_to_webinar
      ? trigger.webinar.registrationFunnelStep.funnel.id
      : undefined

  const triggerCourseId =
    trigger.type === AutomationRuleTriggersEnum.module_completed ||
    trigger.type === AutomationRuleTriggersEnum.lecture_completed
      ? trigger.course.id
      : undefined

  const triggerModuleId =
    trigger.type === AutomationRuleTriggersEnum.lecture_completed ? trigger.module?.id : undefined

  const subscriptionFailedFunnelId =
    trigger.type === AutomationRuleTriggersEnum.subscription_payment_failed
      ? trigger.funnelStep.funnel.id
      : undefined

  const triggerMailingId =
    trigger.type === AutomationRuleTriggersEnum.email_link_clicked ? trigger.mailing.id : undefined

  const { triggersData, setShouldFetch } = useTriggersData({
    trigger: trigger.type,
    blogId,
    formSubscribeFunnelId,
    newSaleFunnelId,
    cancelSaleFunnelId,
    pageVisitedFunnelId,
    blogEntity,
    webinarFunnelId,
    triggerCourseId,
    triggerModuleId,
    triggerMailingId,
    subscriptionFailedFunnelId,
  })

  const renderTriggerLabel = ({
    label,
    additionalLabel,
  }: {
    label: string
    additionalLabel?: JSX.Element
  }) => (
    <div className={'flex gap-1.5'}>
      <div className="text-sm font-medium flex gap-0.5">
        <span>{label}</span>
        <span className="text-danger">*</span>
      </div>
      {additionalLabel}
    </div>
  )

  return (
    <div className={'flex flex-col gap-3'}>
      <div className={'flex justify-between gap-3'}>
        <div className={'flex gap-3'}>
          <div className={'w-[40px] [&>svg]:w-[40px] [&>svg]:h-[40x]'}>
            {getTriggerIcon(trigger.type)}
          </div>
          <div className={'flex gap-1 flex-col'}>
            <span className={'text-darkblue font-bold text-[15px]'}>
              {t(triggerActionLocoKeys[trigger.type])}
            </span>
            <span>{t(triggerDescriptionLocoKeys[trigger.type])}</span>
          </div>
        </div>
        <button onClick={onDelete} className={'outline-none h-fit group'}>
          <CloseIcon
            className={
              'main-transition-colors fill-gray-100 group-hover:fill-darkblue group-focus-visible:fill-darkblue'
            }
          />
        </button>
      </div>
      {children({
        data: triggersData,
        onOpen: (slug: AutomationRuleTriggersDataEnum) =>
          setShouldFetch(prev => ({ ...prev, [slug]: true })),
        onClear: (slug: AutomationRuleTriggersDataEnum) =>
          setShouldFetch(prev => ({ ...prev, [slug]: false })),
        renderTriggerLabel,
      })}
    </div>
  )
}

export default RuleTriggerWrapper
