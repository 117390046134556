import React from 'react'

function EmailOpenedIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="_x32__x2C__message_x2C__Email_x2C__mail_x2C__open">
        <g>
          <g>
            <path d="m16 219.855v269.882l178.304-145.554z" />
            <path d="m25.032 495.273h461.937l-230.969-188.546z" />
            <path d="m496 489.737v-269.882l-178.304 124.328z" />
            <path d="m252.838 296.4c1.84-1.502 4.483-1.502 6.323 0l50.48 41.208 183.067-127.649-236.708-193.232-236.708 193.232 183.067 127.649z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default EmailOpenedIcon
