import { BlogEntityEnum, BlogPageEnum } from 'modules/automation-rules/enums/automation-rule-enum'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { AutomationRulesTriggersEditType } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'

const blogTypesWithPage = [
  BlogEntityEnum.blog_post_body,
  BlogEntityEnum.blog_page,
  BlogPageEnum.blog_home,
  BlogPageEnum.blog_static,
]

export const hasDefaultTrigger = (triggers: AutomationRulesTriggersEditType[]) =>
  triggers.some(trigger => {
    switch (trigger.type) {
      case AutomationRuleTriggersEnum.tag_added:
      case AutomationRuleTriggersEnum.tag_removed: {
        return !trigger.tag.id
      }
      case AutomationRuleTriggersEnum.enrolled_in_course_bundle: {
        return !trigger.courseBundle.id
      }
      case AutomationRuleTriggersEnum.enrolled_in_course:
      case AutomationRuleTriggersEnum.course_completed:
      case AutomationRuleTriggersEnum.module_completed:
      case AutomationRuleTriggersEnum.lecture_completed: {
        return !trigger.course.id
      }
      case AutomationRuleTriggersEnum.campaign_completed: {
        return !trigger.campaign.id
      }
      case AutomationRuleTriggersEnum.enrolled_in_community: {
        // TODO remove ? after backend fix
        return !trigger.community?.id
      }
      case AutomationRuleTriggersEnum.form_subscribed:
      case AutomationRuleTriggersEnum.new_sale:
      case AutomationRuleTriggersEnum.sale_canceled:
      case AutomationRuleTriggersEnum.page_visited:
      case AutomationRuleTriggersEnum.subscription_payment_failed: {
        return !trigger.funnelStep.id || !trigger.funnelStep.funnel.id
      }
      case AutomationRuleTriggersEnum.blog_page_form_subscribed: {
        return (
          !trigger.blog.id ||
          !trigger.page.type ||
          (blogTypesWithPage.includes(trigger.page.type) && !trigger.page.id)
        )
      }
      case AutomationRuleTriggersEnum.registered_to_webinar: {
        return !trigger.webinar.registrationFunnelStep.funnel.id || !trigger.webinar.id
      }
      case AutomationRuleTriggersEnum.email_link_clicked:
      case AutomationRuleTriggersEnum.email_opened: {
        return !trigger.mailing.id
      }
    }
  })
