import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerWebinarEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface TriggerWebinarItemProps extends EditTriggerItemInterface<TriggerWebinarEditInterface> {}

const TriggerWebinarItem = ({
  triggers,
  trigger,
  idx,
  errors,
  onDelete,
  setRules,
  isStatic,
}: TriggerWebinarItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
      {({ data, renderTriggerLabel, onOpen }) => (
        <>
          <FormSelect
            disabled={isStatic}
            onOpen={() => onOpen(AutomationRuleTriggersDataEnum.registered_to_webinar)}
            htmlFor={'none'}
            label={renderTriggerLabel({
              label: t('dashboard.funnel.title'),
              additionalLabel: trigger.webinar.registrationFunnelStep.funnel.id ? (
                <LinkWithoutPrefetch
                  href={`/funnels/${trigger.webinar.registrationFunnelStep.funnel.id}`}
                  rel="noreferrer"
                  target={'_blank'}
                  className={`outline-none group self-center`}
                >
                  <OpenInNewIcon
                    className={
                      'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'
                    }
                  />
                </LinkWithoutPrefetch>
              ) : undefined,
            })}
            placeholder={t('dashboard.automation_rule.choose_funnel')}
            value={trigger.webinar.registrationFunnelStep.funnel.id}
            staticData={getDataFromObj(trigger.webinar.registrationFunnelStep.funnel)}
            data={data.funnelsWebinarData}
            onChange={data =>
              setRules(prev => {
                return {
                  ...prev,
                  triggers: triggers.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        webinar: {
                          id: undefined,
                          registrationFunnelStep: { funnel: { id: data } },
                        },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
            }
            error={errors.automationTriggers[trigger.id]?.funnel?.join('')}
          />
          {trigger.webinar.registrationFunnelStep.funnel.id && (
            <FormSelect
              disabled={isStatic}
              onOpen={() =>
                onOpen(AutomationRuleTriggersDataEnum.registered_to_webinar_funnel_step)
              }
              label={t('dashboard.automation_rule.edit.webinar')}
              placeholder={t('dashboard.automation_rule.choose_webinar')}
              required
              value={trigger.webinar.id}
              staticData={getDataFromObj({
                id: trigger.webinar.id,
                name: trigger.webinar.registrationFunnelStep.name,
              })}
              data={data.webinarData}
              onChange={data =>
                setRules(prev => {
                  return {
                    ...prev,
                    triggers: triggers.map((el, i) => {
                      if (idx === i) {
                        return {
                          ...el,
                          webinar: {
                            id: data,
                            registrationFunnelStep: {
                              funnel: trigger.webinar.registrationFunnelStep.funnel,
                            },
                          },
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
              error={errors.automationTriggers[trigger.id]?.funnelStep?.join('')}
            />
          )}
        </>
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerWebinarItem
