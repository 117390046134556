import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerTagEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface TriggerTagItemProps extends EditTriggerItemInterface<TriggerTagEditInterface> {}

const TriggerTagItem = ({
  triggers,
  trigger,
  idx,
  errors,
  setRules,
  onDelete,
  isStatic,
}: TriggerTagItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
      {({ renderTriggerLabel, data, onOpen }) => (
        <FormSelect
          disabled={isStatic}
          onOpen={() => onOpen(AutomationRuleTriggersDataEnum.tag)}
          label={renderTriggerLabel({
            label: t('dashboard.tag.title'),
            additionalLabel: trigger.tag.id ? (
              <LinkWithoutPrefetch
                href={`/contacts?filter=%5B%7B"searchValue"%3A"${trigger.tag.id}"%2C"compareOperation"%3A"IN_LIST"%2C"searchField"%3A"tag"%7D%5D`}
                rel="noreferrer"
                target={'_blank'}
                className={`outline-none group self-center`}
              >
                <OpenInNewIcon
                  className={'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'}
                />
              </LinkWithoutPrefetch>
            ) : undefined,
          })}
          placeholder={t('dashboard.automation_rule.choose_tag')}
          value={trigger.tag.id}
          staticData={getDataFromObj(trigger.tag)}
          data={data.tagData}
          onChange={data =>
            setRules(prev => {
              return {
                ...prev,
                triggers: triggers.map((el, i) => {
                  if (idx === i) {
                    return { ...el, tag: { id: data } }
                  } else {
                    return el
                  }
                }),
              }
            })
          }
          error={errors.automationTriggers[trigger.id]?.tag?.join('')}
        />
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerTagItem
