import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { AutomationRulesTriggersEditType } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'

export const defaultTriggerValues: Record<
  AutomationRuleTriggersEnum,
  AutomationRulesTriggersEditType
> = {
  [AutomationRuleTriggersEnum.tag_removed]: {
    id: 0,
    type: AutomationRuleTriggersEnum.tag_removed,
    tag: { id: undefined },
  },
  [AutomationRuleTriggersEnum.tag_added]: {
    id: 0,
    type: AutomationRuleTriggersEnum.tag_added,
    tag: { id: undefined },
  },
  [AutomationRuleTriggersEnum.form_subscribed]: {
    id: 0,
    type: AutomationRuleTriggersEnum.form_subscribed,
    funnelStep: { id: undefined, funnel: { id: undefined } },
  },
  [AutomationRuleTriggersEnum.new_sale]: {
    id: 0,
    type: AutomationRuleTriggersEnum.new_sale,
    funnelStep: { id: undefined, funnel: { id: undefined } },
  },
  [AutomationRuleTriggersEnum.sale_canceled]: {
    id: 0,
    type: AutomationRuleTriggersEnum.sale_canceled,
    funnelStep: { id: undefined, funnel: { id: undefined } },
  },
  [AutomationRuleTriggersEnum.page_visited]: {
    id: 0,
    type: AutomationRuleTriggersEnum.page_visited,
    funnelStep: { id: undefined, funnel: { id: undefined } },
  },
  [AutomationRuleTriggersEnum.blog_page_form_subscribed]: {
    id: 0,
    type: AutomationRuleTriggersEnum.blog_page_form_subscribed,
    blog: { id: undefined },
    page: { type: undefined, id: undefined },
  },
  [AutomationRuleTriggersEnum.registered_to_webinar]: {
    id: 0,
    type: AutomationRuleTriggersEnum.registered_to_webinar,
    funnelStep: { id: undefined, funnel: { id: undefined } },
    webinar: {
      id: undefined,
      registrationFunnelStep: { id: undefined, funnel: { id: undefined } },
    },
  },
  [AutomationRuleTriggersEnum.campaign_completed]: {
    id: 0,
    type: AutomationRuleTriggersEnum.campaign_completed,
    campaign: { id: undefined },
  },
  [AutomationRuleTriggersEnum.enrolled_in_course]: {
    id: 0,
    type: AutomationRuleTriggersEnum.enrolled_in_course,
    course: { id: undefined },
  },
  [AutomationRuleTriggersEnum.course_completed]: {
    id: 0,
    type: AutomationRuleTriggersEnum.course_completed,
    course: { id: undefined },
  },
  [AutomationRuleTriggersEnum.module_completed]: {
    id: 0,
    type: AutomationRuleTriggersEnum.module_completed,
    course: { id: undefined },
    module: { id: undefined },
  },
  [AutomationRuleTriggersEnum.lecture_completed]: {
    id: 0,
    type: AutomationRuleTriggersEnum.lecture_completed,
    course: { id: undefined },
    module: { id: undefined },
    lecture: { id: undefined },
  },
  [AutomationRuleTriggersEnum.enrolled_in_course_bundle]: {
    id: 0,
    type: AutomationRuleTriggersEnum.enrolled_in_course_bundle,
    courseBundle: { id: undefined },
  },
  [AutomationRuleTriggersEnum.enrolled_in_community]: {
    id: 0,
    type: AutomationRuleTriggersEnum.enrolled_in_community,
    community: { id: undefined },
  },
  [AutomationRuleTriggersEnum.email_link_clicked]: {
    id: 0,
    type: AutomationRuleTriggersEnum.email_link_clicked,
    mailing: { id: undefined },
    link: { id: undefined },
  },
  [AutomationRuleTriggersEnum.email_opened]: {
    id: 0,
    type: AutomationRuleTriggersEnum.email_opened,
    mailing: { id: undefined },
  },
  [AutomationRuleTriggersEnum.subscription_payment_failed]: {
    id: 0,
    type: AutomationRuleTriggersEnum.subscription_payment_failed,
    funnelStep: { id: undefined, funnel: { id: undefined } },
  },
}
