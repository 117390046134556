import { WrapperChildrenPropsInterface } from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { TriggerCourseItemProps } from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-course-item'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerModuleEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { AutomationRuleBaseDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { Hint } from 'shared/components/hint'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface ModuleSelectProps
  extends Omit<
      TriggerCourseItemProps,
      'onDelete' | 'triggers' | 'setRules' | 'idx' | 'trigger' | 'type'
    >,
    Pick<WrapperChildrenPropsInterface, 'onOpen'> {
  onChange: (data: number) => void
  moduleId?: number
  triggerId: number
  module: TriggerModuleEditInterface['module']
  moduleData?: AutomationRuleBaseDataInterface[]
  hint: string
}

const ModuleSelect = ({
  moduleId,
  triggerId,
  errors,
  onChange,
  onOpen,
  module,
  moduleData,
  hint,
  isStatic,
}: ModuleSelectProps) => {
  const { t } = useLocoTranslation()
  return (
    <FormSelect
      disabled={isStatic}
      onOpen={() => onOpen(AutomationRuleTriggersDataEnum.module)}
      htmlFor={'none'}
      label={<Hint label={hint}>{t('dashboard.course.module_title')}</Hint>}
      placeholder={t('dashboard.automation_rule.trigger.choose_module')}
      value={moduleId}
      staticData={module && getDataFromObj(module)}
      data={moduleData}
      onChange={onChange}
      error={errors.automationTriggers[triggerId]?.module?.join('')}
    />
  )
}

export default ModuleSelect
