import React from 'react'

function ModuleCompletedIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M80.4585 20.8837C80.4585 20.2687 80.4585 19.6516 80.4585 19.0365C80.477 18.9275 80.4996 18.8185 80.5161 18.7094C80.6148 18.0306 80.6704 17.3415 80.8164 16.673C81.6392 12.886 83.5852 9.78194 86.6707 7.44722C90.5091 4.54064 94.8309 3.5759 99.5436 4.55915C103.316 5.34494 106.383 7.32174 108.728 10.3723C111.26 13.6635 112.316 17.4135 111.919 21.5399C111.598 24.8826 110.3 27.8344 108.072 30.3543C105.63 33.1168 102.586 34.8365 98.9511 35.4845C98.3567 35.5894 97.756 35.649 97.1574 35.7293H95.3102C95.23 35.7128 95.1518 35.684 95.0716 35.6778C92.6052 35.503 90.2972 34.7974 88.1908 33.5077C84.1056 31.0043 81.5899 27.3942 80.7074 22.6733C80.5963 22.083 80.5428 21.4802 80.4626 20.8837H80.4585ZM93.8518 22.5108C93.716 22.3833 93.642 22.3154 93.57 22.2434C92.5456 21.2211 91.5232 20.1967 90.4989 19.1764C90.3754 19.053 90.2479 18.9336 90.1121 18.8246C89.207 18.0903 87.8123 18.2301 87.0656 19.127C86.2696 20.0835 86.3498 21.3239 87.2775 22.2557C88.8717 23.8541 90.47 25.4503 92.0663 27.0445C93.1503 28.1286 94.4463 28.1286 95.5324 27.0445C98.7269 23.852 101.919 20.6595 105.11 17.467C105.248 17.3292 105.379 17.1852 105.499 17.0309C106.398 15.8584 105.82 14.0544 104.408 13.6142C103.466 13.3221 102.681 13.5895 102 14.2724C99.3523 16.9239 96.7028 19.5693 94.0554 22.2208C93.9772 22.2989 93.9238 22.4059 93.8518 22.5129V22.5108Z" />
      <path d="M79.463 26.0387H24.6844C23.7752 26.0387 23.0388 26.7751 23.0388 27.6843V93.5091C23.0388 94.4183 22.3023 95.1548 21.3931 95.1548H14.8107C13.9015 95.1548 13.165 94.4183 13.165 93.5091V17.8106C13.165 16.9014 13.9015 16.165 14.8107 16.165H78.6073C78.3892 17.2799 78.2761 18.4298 78.2761 19.6084C78.2761 21.8753 78.6957 24.0434 79.463 26.0387Z" />
      <path d="M11.5193 12.8737H74.0529C74.9621 12.8737 75.6985 12.1373 75.6985 11.2281V4.64562C75.6985 3.73642 74.9621 3 74.0529 3H1.64562C0.736415 3 0 3.73642 0 4.64562V80.3442C0 81.2534 0.736415 81.9898 1.64562 81.9898H8.2281C9.13731 81.9898 9.87372 81.2534 9.87372 80.3442V14.5193C9.87372 13.6101 10.6101 12.8737 11.5193 12.8737Z" />
      <path d="M96.2256 37.5581C96.0282 37.5581 95.8286 37.5539 95.6332 37.5478C95.5715 37.5539 95.5077 37.5581 95.4439 37.5581H67.4684C66.5612 37.5581 65.8228 36.8216 65.8228 35.9124C65.8228 35.4578 66.0079 35.0464 66.3062 34.7482C66.6045 34.4519 67.0158 34.2668 67.4684 34.2668H85.8644C83.9884 32.94 82.3798 31.2615 81.1333 29.33H27.9735C27.0664 29.33 26.3279 30.0664 26.3279 30.9756V40.8493C26.3279 41.7585 27.0664 42.4949 27.9735 42.4949H100.381C101.29 42.4949 102.026 41.7585 102.026 40.8493V36.6015C100.208 37.2207 98.2559 37.5581 96.2256 37.5581ZM34.0747 37.0767C33.7764 37.3729 33.365 37.5581 32.9104 37.5581C32.4558 37.5581 32.0464 37.3729 31.7482 37.0767C31.4499 36.7784 31.2647 36.367 31.2647 35.9124C31.2647 35.4578 31.4499 35.0464 31.7482 34.7482C32.0464 34.4519 32.4578 34.2668 32.9104 34.2668C33.8196 34.2668 34.556 35.0032 34.556 35.9124C34.556 36.367 34.3709 36.7784 34.0747 37.0767ZM40.6572 37.0767C40.3589 37.3729 39.9474 37.5581 39.4928 37.5581C39.0382 37.5581 38.6289 37.3729 38.3306 37.0767C38.0324 36.7784 37.8472 36.367 37.8472 35.9124C37.8472 35.4578 38.0324 35.0464 38.3306 34.7482C38.6289 34.4519 39.0403 34.2668 39.4928 34.2668C40.4021 34.2668 41.1385 35.0032 41.1385 35.9124C41.1385 36.367 40.9534 36.7784 40.6572 37.0767ZM47.2396 37.0767C46.9414 37.3729 46.5299 37.5581 46.0753 37.5581C45.6207 37.5581 45.2114 37.3729 44.9131 37.0767C44.6149 36.7784 44.4297 36.367 44.4297 35.9124C44.4297 35.4578 44.6149 35.0464 44.9131 34.7482C45.2114 34.4519 45.6228 34.2668 46.0753 34.2668C46.9845 34.2668 47.7209 35.0032 47.7209 35.9124C47.7209 36.367 47.5358 36.7784 47.2396 37.0767Z" />
      <path d="M100.381 45.7861H27.9735C27.0664 45.7861 26.3279 46.5225 26.3279 47.4318V106.674C26.3279 107.583 27.0664 108.32 27.9735 108.32H100.381C101.29 108.32 102.026 107.583 102.026 106.674V47.4318C102.026 46.5225 101.29 45.7861 100.381 45.7861ZM48.0439 92.1865C39.6862 92.1865 32.9104 85.4106 32.9104 77.0529C32.9104 68.6952 39.6862 61.9194 48.0439 61.9194C56.4016 61.9194 63.1754 68.6952 63.1754 77.0529C63.1754 85.4106 56.4016 92.1865 48.0439 92.1865ZM94.2344 91.4459C93.7778 91.9026 93.1484 92.1865 92.4572 92.1865H71.8972C70.5107 92.1865 69.3794 91.053 69.3794 89.6687C69.3794 88.9754 69.6612 88.346 70.1179 87.8914C70.5745 87.4347 71.204 87.1509 71.8972 87.1509H92.4572C93.8416 87.1509 94.975 88.2843 94.975 89.6687C94.975 90.3619 94.6911 90.9913 94.2344 91.4459ZM94.2344 79.0503C93.7778 79.507 93.1484 79.7908 92.4572 79.7908H71.8972C70.5107 79.7908 69.3794 78.6574 69.3794 77.271C69.3794 76.5798 69.6612 75.9504 70.1179 75.4937C70.5745 75.037 71.204 74.7532 71.8972 74.7532H92.4572C93.8416 74.7532 94.975 75.8866 94.975 77.271C94.975 77.9642 94.6911 78.5936 94.2344 79.0503ZM94.2344 66.6526C93.7778 67.1093 93.1484 67.3931 92.4572 67.3931H71.8972C70.5107 67.3931 69.3794 66.2597 69.3794 64.8753C69.3794 64.1842 69.6612 63.5547 70.1179 63.0981C70.5745 62.6414 71.204 62.3575 71.8972 62.3575H92.4572C93.8416 62.3575 94.975 63.491 94.975 64.8753C94.975 65.5665 94.6911 66.1959 94.2344 66.6526Z" />
      <path d="M43.7734 73.5909V80.5149C43.7734 81.5948 44.911 82.2983 45.8778 81.8149L52.8018 78.353C53.8735 77.8181 53.8735 76.2877 52.8018 75.7529L45.8778 72.2909C44.911 71.8075 43.7734 72.511 43.7734 73.5909Z" />
    </svg>
  )
}

export default ModuleCompletedIcon
