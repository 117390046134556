import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionCourseEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { AutomationRuleCourseAccessDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import {
  AccessTypeCaptions,
  AccessTypeEnum,
} from 'modules/courses/courses/curriculum/enums/access-type-enum'
import { useCourseCurriculum } from 'modules/courses/courses/curriculum/hooks/use-course-curriculum'
import { getCourseCurriculumUrl } from 'modules/courses/courses/curriculum/utils/get-course-curriculum-url'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import FormSelectMultiple from 'shared/components/form-select-multiple'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import RadioGroup from 'shared/components/radio-group'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'
import { AutomationRuleActionsEnum } from '../../../enums/action-enum'

interface ActionCourseItemProps extends EditActionItemInterface<ActionCourseEditInterface> {}

const ActionCourseItem = ({
  actions,
  action,
  errors,
  setRules,
  onDelete,
  idx,
}: ActionCourseItemProps) => {
  const { t } = useLocoTranslation()

  const courseAccessTypeData: AutomationRuleCourseAccessDataInterface[] = [
    { id: AccessTypeEnum.fullAccess, caption: t(AccessTypeCaptions[AccessTypeEnum.fullAccess]) },
    {
      id: AccessTypeEnum.drippingContent,
      caption: t(AccessTypeCaptions[AccessTypeEnum.drippingContent]),
    },
    {
      id: AccessTypeEnum.partialAccess,
      caption: t(AccessTypeCaptions[AccessTypeEnum.partialAccess]),
    },
  ]

  const isEnrollInCourse = action.type === AutomationRuleActionsEnum.enroll_in_course
  const isPartialAccess = action.accessType === AccessTypeEnum.partialAccess

  const { courseCurriculumData } = useCourseCurriculum(
    isEnrollInCourse && isPartialAccess ? action.course.id : undefined,
  )

  const courseModulesData =
    courseCurriculumData?.modules.map(({ id, name }) => ({
      id,
      caption: name,
    })) ?? []

  const cleanUpCourseModules = () => {
    setRules(prev => {
      return {
        ...prev,
        actions: prev.actions.map((el, i) => {
          if (idx === i) {
            if (el.type !== AutomationRuleActionsEnum.enroll_in_course) {
              return el
            }

            if (el.accessType == AccessTypeEnum.partialAccess) {
              const { courseModules, ...rest } = el
              return { ...rest, courseModules: [] }
            } else {
              const { courseModules, ...rest } = el
              return rest
            }
          } else {
            return el
          }
        }),
      }
    })
  }

  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <>
          <FormSelect
            htmlFor={'none'}
            onOpen={onOpen}
            labelClassName={'w-full'}
            label={renderActionLabel({
              label: t('dashboard.courses.title'),
              additionalLabel: action.course.id ? (
                <LinkWithoutPrefetch
                  href={getCourseCurriculumUrl({ courseId: action.course.id })}
                  rel="noreferrer"
                  target={'_blank'}
                  className={`outline-none group self-center`}
                >
                  <OpenInNewIcon
                    className={
                      'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'
                    }
                  />
                </LinkWithoutPrefetch>
              ) : undefined,
            })}
            placeholder={t('dashboard.automation_rule.choose_course')}
            value={action.course.id}
            data={data.courseData}
            staticData={getDataFromObj(action.course)}
            onChange={data => {
              setRules(prev => {
                return {
                  ...prev,
                  actions: prev.actions.map((el, i) => {
                    if (idx === i) {
                      return { ...el, course: { id: data } }
                    } else {
                      return el
                    }
                  }),
                }
              })
              cleanUpCourseModules()
            }}
            error={errors.automationActions[action.id]?.course?.join('')}
          />
          {isEnrollInCourse && (
            <>
              <RadioGroup
                options={courseAccessTypeData}
                selected={action.accessType}
                onChange={data => {
                  //@ts-expect-error
                  setRules(prev => {
                    return {
                      ...prev,
                      actions: prev.actions.map((el, i) => {
                        if (idx === i) {
                          return { ...el, accessType: data }
                        } else {
                          return el
                        }
                      }),
                    }
                  })
                  cleanUpCourseModules()
                }}
                error={errors.automationActions[action.id]?.courseAccessType?.flat().join('')}
              />
              {isPartialAccess && (
                <FormSelectMultiple
                  required
                  label={t('dashboard.course.module_title')}
                  placeholder={'dashboard.automation_rule.trigger.choose_module'}
                  data={courseModulesData}
                  value={action.courseModules.map(({ id }) => id)}
                  withoutCloseIcon={!action.courseModules?.length}
                  onChange={data =>
                    setRules(prev => {
                      return {
                        ...prev,
                        actions: prev.actions.map((el, i) => {
                          if (
                            idx === i &&
                            el.type === AutomationRuleActionsEnum.enroll_in_course &&
                            el.accessType === AccessTypeEnum.partialAccess
                          ) {
                            return {
                              ...el,
                              courseModules:
                                courseCurriculumData?.modules?.filter(({ id }) =>
                                  data.includes(id),
                                ) ?? [],
                            }
                          } else {
                            return el
                          }
                        }),
                      }
                    })
                  }
                  error={errors.automationActions[action.id]?.courseModules?.flat().join('')}
                />
              )}
            </>
          )}
        </>
      )}
    </EditActionWrapper>
  )
}

export default ActionCourseItem
