import { ruleIconClassName } from 'modules/automation-rules/constants/automation-rule-icon-class'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import AddTagIcon from 'shared/icons/add-tag-icon'
import CampaignCompleteIcon from 'shared/icons/campaign-complete-icon'
import CommunityIcon from 'shared/icons/community-icon'
import CourseCompletedIcon from 'shared/icons/course-completed-icon'
import CourseIcon from 'shared/icons/course-icon'
import EmailLinkClickedIcon from 'shared/icons/email-link-clicked-icon'
import EmailOpenedIcon from 'shared/icons/email-opened-icon'
import FormSubscribedIcon from 'shared/icons/form-subscribed-icon'
import LectureCompletedIcon from 'shared/icons/lecture-completed-icon'
import ModuleCompletedIcon from 'shared/icons/module-completed-icon'
import NewSaleIcon from 'shared/icons/new-sale-icon'
import PageVisitedIcon from 'shared/icons/page-visited-icon'
import RemoveTagIcon from 'shared/icons/remove-tag-icon'
import SaleCanceledIcon from 'shared/icons/sale-canceled-icon'
import SubscriptionPaymentFailedIcon from 'shared/icons/subscription-payment-failed-icon'
import WebinarIcon from 'shared/icons/webinar-icon'

export const getTriggerIcon = (trigger: AutomationRuleTriggersEnum): JSX.Element => {
  switch (trigger) {
    case AutomationRuleTriggersEnum.tag_added:
      return <AddTagIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.tag_removed:
      return <RemoveTagIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.registered_to_webinar:
      return <WebinarIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.enrolled_in_course:
    case AutomationRuleTriggersEnum.enrolled_in_course_bundle:
      return <CourseIcon className={`fill-gray-100 ${ruleIconClassName}`} />
    case AutomationRuleTriggersEnum.course_completed:
      return <CourseCompletedIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.module_completed:
      return <ModuleCompletedIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.lecture_completed:
      return <LectureCompletedIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.form_subscribed:
    case AutomationRuleTriggersEnum.blog_page_form_subscribed:
      return <FormSubscribedIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.campaign_completed:
      return <CampaignCompleteIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.new_sale:
      return <NewSaleIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.sale_canceled:
      return <SaleCanceledIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.email_link_clicked:
      return <EmailLinkClickedIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.email_opened:
      return <EmailOpenedIcon className={`${ruleIconClassName} fill-gray-100`} />
    case AutomationRuleTriggersEnum.page_visited:
      return <PageVisitedIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.enrolled_in_community:
      return <CommunityIcon className={ruleIconClassName} />
    case AutomationRuleTriggersEnum.subscription_payment_failed:
      return <SubscriptionPaymentFailedIcon className={ruleIconClassName} />
  }
}
