import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { AUTOMATION_RULES_API } from '../api/automation-rules-api'
import { AutomationRuleViewInterface } from '../types/automation-rule-interface'

export const useCreateRule = (
  props?: Pick<Parameters<typeof useApiWrapperWithErrorValidation>[0], 'showFieldsErrorToast'>,
) => {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    AutomationRuleViewInterface
  >({
    method: RequestMethodsEnum.post,
    ...props,
  })
  return {
    fetcher: async (data: string) =>
      fetcher(`${AUTOMATION_RULES_API}`, data, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }),
  }
}
