import {
  triggerActionLocoKeys,
  triggerDescriptionLocoKeys,
} from 'modules/automation-rules/triggers/constants/trigger-loco-keys'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { getTriggerIcon } from 'modules/automation-rules/triggers/utils/get-trigger-icon'

export interface TriggerItem {
  slug: AutomationRuleTriggersEnum
  title: string
  description: string
  icon: JSX.Element
}

export const triggerItems: TriggerItem[] = Object.values(AutomationRuleTriggersEnum).map(slug => ({
  slug: slug,
  title: triggerActionLocoKeys[slug],
  description: triggerDescriptionLocoKeys[slug],
  icon: getTriggerIcon(slug),
}))
