import {
  createRuleEmail,
  createRuleSpecificEmail,
} from 'modules/automation-rules/email/api/rule-email-api'
import { FilterEmailApiInterface } from 'modules/automation-rules/types/automation-rule-filter-interface'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import DashboardMailEditor from 'shared/components/dashboard-mail-editor'
import { updateAttachments } from 'shared/components/dashboard-mail-editor/api/email-api'
import { UnsavedEmailsEnum } from 'shared/components/dashboard-mail-editor/enums/unsaved-emails-enum'
import { useUnsavedEmail } from 'shared/components/dashboard-mail-editor/hooks/use-unsaved-email'
import {
  AttachmentFileInterface,
  EmailBaseInterface,
  EmailErrorResponseContentInterface,
  EmailErrorType,
  EmailRecipientErrorResponseContentInterface,
  EmailRecipientInterface,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import { getStyledBody } from 'shared/components/dashboard-mail-editor/utils/get-styled-body'
import Modal, { ModalSizeEnum } from 'shared/components/modal'
import { Tooltip } from 'shared/components/tooltip'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'
import { isValidEmail } from 'shared/utils/email-validation'
import { KeyedMutator } from 'swr'

const defaultValues: EmailRecipientInterface = {
  body: '',
  subject: '',
  previewText: '',
  fromName: '',
  fromEmail: '',
  recipient: '',
}

const defaultErrors: EmailErrorType = {
  body: '',
  fromEmail: '',
  fromName: '',
  subject: '',
  previewText: '',
  common: [],
}

interface RuleEmailCreateModalProps {
  withRecipient?: boolean
  mutateEmailData: KeyedMutator<FilterEmailApiInterface[]> | undefined
  mutateSpecificEmailData: KeyedMutator<FilterEmailApiInterface[]> | undefined
  onSetNewEmail: (data: { id: number; subject: string }) => void
}

const RuleEmailCreateModal = ({
  withRecipient,
  mutateEmailData,
  mutateSpecificEmailData,
  onSetNewEmail,
}: RuleEmailCreateModalProps) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const [tempState, setTempState] = useState<EmailRecipientInterface>(defaultValues)
  const [errors, setErrors] = useState<EmailErrorType>(defaultErrors)

  const [attachmentsTempState, setAttachmentsTempState] = useState<
    (AttachmentFileInterface | File)[]
  >([])

  const onCreate = async () => {
    try {
      if (tempState) {
        setErrors(defaultErrors)
        if (!isValidEmail(tempState.fromEmail)) {
          return setErrors(prev => ({
            ...prev,
            fromEmail: t('dashboard.validation.email_not_valid'),
          }))
        }
        if (withRecipient && !isValidEmail(tempState.recipient)) {
          return setErrors(prev => ({
            ...prev,
            recipient: t('dashboard.validation.email_not_valid'),
          }))
        }
        setIsFetching(true)
        const currentEmail: EmailBaseInterface = {
          fromEmail: tempState.fromEmail,
          fromName: tempState.fromName,
          subject: tempState.subject,
          previewText: tempState.previewText,
          body: getStyledBody(tempState.body),
        }
        if (withRecipient) {
          const emailResponse = await createRuleSpecificEmail({
            recipientAddress: tempState.recipient,
            mailing: {
              currentEmail,
            },
          })
          await updateAttachments(emailResponse.data.mailing.currentEmail.id, attachmentsTempState)
          if (mutateSpecificEmailData) {
            await mutateSpecificEmailData(data => {
              if (data) {
                return [
                  {
                    id: emailResponse.data.id,
                    subject: emailResponse.data.mailing.currentEmail.subject,
                  },
                  ...data,
                ]
              }
            }, false)
          }
          onSetNewEmail({
            id: emailResponse.data.id,
            subject: emailResponse.data.mailing.currentEmail.subject,
          })
        } else {
          const emailResponse = await createRuleEmail(currentEmail)
          await updateAttachments(emailResponse.data.currentEmail.id, attachmentsTempState)
          if (mutateEmailData) {
            await mutateEmailData(data => {
              if (data) {
                return [
                  {
                    id: emailResponse.data.currentEmail.id,
                    subject: emailResponse.data.currentEmail.subject,
                  },
                  ...data,
                ]
              }
            }, false)
          }
          onSetNewEmail({
            id: emailResponse.data.currentEmail.id,
            subject: emailResponse.data.currentEmail.subject,
          })
        }
        toast.success(t('global.changes_saved'))
        await removeDbRecord()
        setIsFetching(false)
        setOpened(false)
      }
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (withRecipient) {
          const errors = e.errors as EmailRecipientErrorResponseContentInterface
          if (errors.fields) {
            if (errors.fields.recipientAddress) {
              setErrors(prev => ({
                ...prev,
                recipient: errors.fields.recipientAddress?.join(''),
              }))
            }
            const currentEmail = errors.fields.mailing?.currentEmail
            if (currentEmail) {
              setErrors(prev => ({
                ...prev,
                body: currentEmail.body?.join(''),
                fromEmail: currentEmail.fromEmail?.join(''),
                fromName: currentEmail.fromName?.join(''),
                subject: currentEmail.subject?.join(''),
                previewText: currentEmail.previewText?.join(''),
              }))
            }
          }
        } else {
          const errors = e.errors as EmailErrorResponseContentInterface
          if (errors.fields) {
            const fields = errors.fields as unknown as Record<keyof EmailBaseInterface, string[]>
            setErrors(prev => ({
              ...prev,
              body: fields.body?.join(''),
              fromEmail: fields.fromEmail?.join(''),
              fromName: fields.fromName?.join(''),
              subject: fields.subject?.join(''),
              previewText: fields.previewText?.join(''),
            }))
          }
        }
        if (e.errors.common) {
          const commonErrors = e.errors.common
          setErrors(prev => {
            return {
              ...prev,
              common: commonErrors,
            }
          })
        }
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const { setCkeditorRef, CkeditorUnsavedEmail, removeDbRecord, setNeedToShowModal } =
    useUnsavedEmail({
      type: withRecipient
        ? UnsavedEmailsEnum.create_rule_email_with_recipient
        : UnsavedEmailsEnum.create_rule_email,
      currentBody: tempState?.body,
      initialBody: '',
    })

  const onChangeEmail = (value: EmailRecipientInterface) => setTempState(value)
  return (
    <>
      <Tooltip mode={'hover'} label={t('dashboard.automation_rule.edit.create_email')}>
        <button onClick={() => setOpened(true)} className={'group outline-none'}>
          <AddIcon
            className={
              'fill-gray-100 main-transition-colors group-hover:fill-blue group-focus-visible:fill-blue'
            }
          />
        </button>
      </Tooltip>
      <Modal
        afterLeave={() => {
          setErrors(defaultErrors)
          setTempState(defaultValues)
        }}
        afterEnter={() => setNeedToShowModal(true)}
        isFetching={isFetching}
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('dashboard.automation_rule.edit.create_email')}
        size={ModalSizeEnum.big}
      >
        <form className="flex flex-col gap-6 lg:gap-10">
          <DashboardMailEditor
            withRecipient={withRecipient}
            inModal
            isPreFetching={false}
            tempState={tempState}
            onChangeEmail={onChangeEmail}
            attachmentsTempState={attachmentsTempState}
            setAttachmentsTempState={setAttachmentsTempState}
            errors={errors}
            setCkeditorRef={setCkeditorRef}
          />
          <div className={'flex justify-center'}>
            <PrimaryButton
              width="large"
              type={'submit'}
              onClick={async e => {
                e.preventDefault()
                onCreate()
              }}
              isFetching={isFetching}
            >
              {t('global.save')}
            </PrimaryButton>
          </div>
        </form>
        {CkeditorUnsavedEmail}
      </Modal>
    </>
  )
}

export default RuleEmailCreateModal
