import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import CourseSelect from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-course-item/components/course-select'
import ModuleSelect from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-module-item/components/module-select'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerLectureEditInterface } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import { Hint } from 'shared/components/hint'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface TriggerLectureItemProps extends EditTriggerItemInterface<TriggerLectureEditInterface> {}

const TriggerLectureItem = ({
  trigger,
  triggers,
  idx,
  errors,
  setRules,
  onDelete,
  isStatic,
}: TriggerLectureItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditTriggerWrapper trigger={trigger} onDelete={() => onDelete(idx)}>
      {({ data, renderTriggerLabel, onOpen, onClear }) => (
        <>
          <CourseSelect
            isStatic={isStatic}
            onChange={data => {
              setRules(prev => {
                return {
                  ...prev,
                  triggers: triggers.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        course: { id: data },
                        module: { id: undefined },
                        lecture: { id: undefined },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
              onClear(AutomationRuleTriggersDataEnum.module)
              onClear(AutomationRuleTriggersDataEnum.lecture)
            }}
            triggerId={trigger.id}
            courseData={data.courseData}
            courseId={trigger.course.id}
            course={trigger.course}
            errors={errors}
            renderTriggerLabel={renderTriggerLabel}
            onOpen={onOpen}
            hint={t('dashboard.automation_rule.trigger.lecture.course_hint')}
          />

          {trigger.course.id && (
            <ModuleSelect
              isStatic={isStatic}
              triggerId={trigger.id}
              onOpen={onOpen}
              module={trigger.module}
              onChange={data => {
                setRules(prev => {
                  return {
                    ...prev,
                    triggers: triggers.map((el, i) => {
                      if (idx === i) {
                        return {
                          ...el,
                          module: { id: data },
                          lecture: { id: undefined },
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                })
                onClear(AutomationRuleTriggersDataEnum.lecture)
              }}
              errors={errors}
              moduleId={trigger.module?.id}
              moduleData={data.moduleData}
              hint={t('dashboard.automation_rule.trigger.lecture.module_hint')}
            />
          )}
          {trigger.module?.id && (
            <FormSelect
              disabled={isStatic}
              onOpen={() => onOpen(AutomationRuleTriggersDataEnum.lecture)}
              htmlFor={'none'}
              label={
                <Hint label={t('dashboard.automation_rule.trigger.lecture.lecture_hint')}>
                  {t('dashboard.course.lecture_title')}
                </Hint>
              }
              placeholder={t('dashboard.automation_rule.trigger.choose_lecture')}
              value={trigger.lecture?.id}
              staticData={trigger.lecture && getDataFromObj(trigger.lecture)}
              data={data.lectureData}
              onChange={data =>
                setRules(prev => {
                  return {
                    ...prev,
                    triggers: triggers.map((el, i) => {
                      if (idx === i) {
                        return {
                          ...el,
                          lecture: { id: data },
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
              error={errors.automationTriggers[trigger.id]?.lecture?.join('')}
            />
          )}
        </>
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerLectureItem
