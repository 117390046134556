import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import { ActionCourseBundleEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { AutomationRuleCourseAccessDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import {
  AccessTypeCaptions,
  AccessTypeEnum,
} from 'modules/courses/courses/curriculum/enums/access-type-enum'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import RadioGroup from 'shared/components/radio-group'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface ActionCourseBundleItemProps
  extends EditActionItemInterface<ActionCourseBundleEditInterface> {}

const ActionCourseBundleItem = ({
  actions,
  action,
  errors,
  setRules,
  onDelete,
  idx,
}: ActionCourseBundleItemProps) => {
  const { t } = useLocoTranslation()
  const courseAccessTypeData: AutomationRuleCourseAccessDataInterface[] = [
    { id: AccessTypeEnum.fullAccess, caption: t(AccessTypeCaptions[AccessTypeEnum.fullAccess]) },
    {
      id: AccessTypeEnum.drippingContent,
      caption: t(AccessTypeCaptions[AccessTypeEnum.drippingContent]),
    },
  ]

  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <>
          <FormSelect
            htmlFor={'none'}
            onOpen={onOpen}
            labelClassName={'w-full'}
            label={renderActionLabel({
              label: t('dashboard.course_bundle.title_one'),
            })}
            placeholder={t('dashboard.automation_rule.choose_course_bundle')}
            value={action.courseBundle?.id}
            data={data.courseBundleData}
            staticData={getDataFromObj(action.courseBundle ?? {})}
            onChange={data =>
              setRules(prev => {
                return {
                  ...prev,
                  actions: actions.map((el, i) => {
                    if (idx === i) {
                      return { ...el, courseBundle: { id: data } }
                    } else {
                      return el
                    }
                  }),
                }
              })
            }
            error={errors.automationActions[action.id]?.courseBundle?.join('')}
          />
          {action.type === AutomationRuleActionsEnum.enroll_in_course_bundle && (
            <RadioGroup
              options={courseAccessTypeData}
              selected={action.enrollmentAccessType}
              onChange={data =>
                setRules(prev => {
                  return {
                    ...prev,
                    actions: actions.map((el, i) => {
                      if (idx === i) {
                        return { ...el, enrollmentAccessType: data }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
            />
          )}
        </>
      )}
    </EditActionWrapper>
  )
}

export default ActionCourseBundleItem
